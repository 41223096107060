import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class StatusConfigService {
  constructor(private translateService: TranslateService) {}

  getMeToolTip(status: string): string {
    switch (status) {
      case 'SUBMITTED':
        return this.translateService.instant('MODULES.DATA.UPLOAD.SUBMITTED');
      case 'IN_PROGRESS':
        return this.translateService.instant('MODULES.DATA.UPLOAD.IN_PROGRESS');
      case 'PARTIAL_SUCCESS':
        return this.translateService.instant('MODULES.DATA.UPLOAD.PARTIAL_SUCCESS');
      case 'SUCCESS':
        return this.translateService.instant('MODULES.DATA.UPLOAD.SUCCESS');
      case 'FAILED':
        return this.translateService.instant('MODULES.DATA.UPLOAD.FAILED');
      default:
        return '';
    }
  }
}
