import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LayoutService } from '@app/layout/services/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  rightSidebarVisible = false;

  isSessionTimeoutVisible = false;

  // Store submenu items
  activeSubmenuItems: any[] = [];

  selectedSubmenu: any = null;

  constructor(public layoutService: LayoutService, private router: Router) {
    // access session expired component in right sidebar
    this.layoutService.sessionTimeoutRightSidebar.subscribe((res: boolean) => {
      this.isSessionTimeoutVisible = res;
    });
  }

  // temporary check
  toggleRightSidebar(): void {
    this.rightSidebarVisible = !this.rightSidebarVisible;
  }

  // Get selected submenu based on parent selection
  updateSubmenu(submenuItems: any[]): void {
    this.activeSubmenuItems = submenuItems || [];
  }

  // Handle when a parent menu item is clicked (coming from <app-menu>)
  onMenuItemClicked(subMenuList: any): void {
    this.selectedSubmenu = subMenuList.activeSubMenu;
    if (subMenuList.item.submenu && subMenuList.item.submenu.length > 0) {
      this.updateSubmenu(subMenuList.item.submenu); // Update submenu with the clicked item's children
    } else {
      if (subMenuList.item.routerLink?.length > 0) {
        this.router.navigate(subMenuList.item.routerLink);
      }
      this.updateSubmenu([]); // No submenu items, clear it
    }
  }
}
