import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';

const routes = {
  complianceHeaders: `${environment.SLMUrl}/reports/data/compliance`,
  incidentHeaders: `${environment.SLMUrl}/reports/data/insights_incidents`,
  rawKPIAgg: `${environment.SLMUrl}/reports/data/kpi_raw_aggregator`,
  rawKPI: `${environment.SLMUrl}/reports/data/kpi_raw`,
  uploadKPI: `${environment.SLMUrl}/reports`,
  reportHierarchy: `${environment.SLMUrl}/hierarchies/getTreeByHierarchyName/Folders`,
  reportData: `${environment.SLMUrl}/reports`,
  hierarchyData: `${environment.SLMUrl}/hierarchies/getHierarchyTreeById`,
  udfRoute: `${environment.distributionUrl}/distributionvariables/valuesets`,
};

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  viewUploadData = new BehaviorSubject<any>('');

  getBusinessId = new BehaviorSubject<any>('');

  uploadResponse = new Subject<any>();

  updatedReport = new Subject<any>();

  constructor(private httpClient: HttpClient) {}

  getUploadRes(data: any): void {
    this.uploadResponse.next(data);
  }

  getUpdatedReport(data: any): void {
    this.updatedReport.next(data);
  }

  getComplianceData(filter: string, params: any = {}): any {
    return this.httpClient.get(`${routes.complianceHeaders}?${filter}`, { params });
  }

  getIncidentData(filter: string, params: any = {}): any {
    return this.httpClient.get(`${routes.incidentHeaders}?${filter}`, { params });
  }

  getRawKPIAggData(params: any = {}): any {
    return this.httpClient.get(`${routes.rawKPIAgg}`, { params });
  }

  getRawKPIData(params: any = {}): any {
    return this.httpClient.get(`${routes.rawKPI}`, { params });
  }

  uploadKPIData(id: number, params: any, payload: any): any {
    return this.httpClient.post(`${routes.uploadKPI}/${id}/uploads?`, payload, { params });
  }

  getUploadViewData(data: any): void {
    this.viewUploadData.next(data);
  }

  getBusinessInfo(data: any): any {
    this.getBusinessId.next(data);
  }

  getReportHierarchy(params: any = {}): any {
    return this.httpClient.get(`${routes.reportHierarchy}`, { params });
  }

  getReportData(id: number, body: any, params: any = {}): any {
    return this.httpClient.post(`${routes.reportData}/${id}/data`, body, { params });
  }

  getUploadHeader(id: number): any {
    return this.httpClient.get(`${routes.reportData}/${id}/uploads/headers`);
  }

  getFormAttributes(id: number, params: any = {}): any {
    return this.httpClient.get(`${routes.reportData}/uploaders/${id}/attributes`, { params });
  }

  getHierarchyFieldData(id: number): any {
    return this.httpClient.get(`${routes.hierarchyData}/${id}`);
  }

  /** Initiate Api trigger to get valueset id */
  getValueSet(id: number | string): any {
    return this.httpClient.get(`${routes.udfRoute}/${id}/keys`);
  }

  addWebForm(id: number, payload: any): any {
    return this.httpClient.post(`${routes.reportData}/uploaders/${id}/addwebformdata`, payload);
  }
}
