<ul class="menu" #menuWrapper>
  <ng-container *ngFor="let item of menuItems">
    <li
      class="menu-item px-3"
      [ngClass]="{
        'has-children': item.children?.length,
        'disabled-item': item.isDeleted || !item.isAccessible,
        'hover-item': !item.isDeleted && item.isAccessible
      }"
      [pTooltip]="item.tooltip"
      tooltipPosition="bottom"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div
          class="d-flex align-items-center"
          (click)="routeNavigate(item)"
          [attr.role]="
            !item.isDeleted &&
            item.isAccessible &&
            item.favTypeName !== 'action'
              ? 'button'
              : null
          "
        >
          <img
            [src]="getIcon(item.favTypeName)"
            alt="icon"
            class="mr-3"
            height="24"
          />
          <div>
            <p class="mb-2 label actionMenuItems" [class.fw-bold]="item.isNew">
              {{ item.name }}
            </p>
            <p *ngIf="item.breadCrumbs" class="mb-0 primary-text path">
              {{ item.breadCrumbs }}
            </p>
          </div>
        </div>
        <i
          *ngIf="item.children?.length"
          class="pi pi-angle-right icon-color"
        ></i>

        <!-- status of action items -->
        <div
          *ngIf="
            item?.state &&
            item.favTypeName === 'action' &&
            item.isAccessible &&
            !item.isDeleted
          "
        >
          <span
            [ngClass]="
              item.state === 'Completed'
                ? 'success-chip'
                : item.state === 'In Progress'
                ? 'warning-chip'
                : item.state === 'Failed'
                ? 'danger-chip'
                : ''
            "
            class="mr-2"
          >
            {{ item?.state }}
          </span>
          <app-actions [actionMenuItem]="item"></app-actions>
        </div>
      </div>

      <!-- Recursive call for children items if they exist -->
      <ul *ngIf="item.children?.length" class="submenu">
        <app-fav-menu [menuItems]="item.children"></app-fav-menu>
      </ul>
    </li>
  </ng-container>
</ul>
