<div class="container-fluid">
  <div class="row">
    <div class="d-flex justify-content-end">
      <div class="col-3 text-right">
        <button
          pButton
          type="button"
          class="p-button mb-0"
          label="{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}"
          (click)="clearGrid()"
          [disabled]="!haveFilters"
        ></button>
      </div>
    </div>
  </div>
  <div class="row">
    <ag-grid-angular
      class="ag-theme-balham custom-ag-table"
      [columnDefs]="headers"
      [suppressRowTransform]="true"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      (sortChanged)="onSortChange()"
      (cellClicked)="onCellClick($event)"
      (filterModified)="onFilterModified($event)"
      #agGrid
    >
    </ag-grid-angular>
  </div>
  <!-- upload right sidebar -->
  <p-sidebar
    [(visible)]="isUpload"
    position="right"
    styleClass="p-sidebar-md"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isUpload"
  >
    <app-upload (closeClicked)="isUpload = $event"></app-upload>
  </p-sidebar>

  <!-- Row adding right sidebar -->
  <p-sidebar
    [(visible)]="isAddEnabled"
    position="right"
    styleClass="p-sidebar-md"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isAddEnabled"
  >
    <app-webform (closeClicked)="isAddEnabled = $event"></app-webform>
  </p-sidebar>
</div>
