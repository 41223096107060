<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>SESSION_DIALOG.SESSION</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
  <div class="row mt-2">
    <div class="col-12 col-sm-12">
      <p translate>SESSION_DIALOG.MESSAGE</p>
    </div>
    <div class="col-12 col-sm-12">
      <button
        class="p-button"
        pButton
        label="{{ 'SESSION_DIALOG.CLOSE' | translate }}"
        (click)="takeToLogin()"
      ></button>
    </div>
  </div>
</div>
