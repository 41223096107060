import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from '../../../../environments/environment';

const routes = {
  calculationRoute: `${environment.SLMUrl}/reports`,
  details: `${environment.SLMUrl}/calculation/status`,
  CalculationInitiate: `${environment.SLMUrl}/calculation/initiate`,
  calculationFlowData: `${environment.SLMUrl}/calculation/flows`,
};

@Injectable({
  providedIn: 'root',
})
export class CalculationsService {
  reportIdentifier = 'KPU Details';

  calculationData = new BehaviorSubject<any>('');

  updatedTableData = new Subject<any>();

  constructor(private httpClient: HttpClient) {}

  getCalculationData(data: any): void {
    this.calculationData.next(data);
  }

  getupdatedData(data: any): void {
    this.updatedTableData.next(data);
  }

  getHeaders(): any {
    return this.httpClient.get(`${routes.calculationRoute}/${this.reportIdentifier}/header`);
  }

  getData(body?: any, params: any = {}): any {
    return this.httpClient.post(`${routes.calculationRoute}/${this.reportIdentifier}/data`, body, { params });
  }

  getStatusDetails(processorId: number): any {
    return this.httpClient.get(`${routes.details}/${processorId}`);
  }

  getActionDetails(processorId: number): any {
    return this.httpClient.post(`${routes.CalculationInitiate}/${processorId}`, {});
  }

  getFlowDataForCalculations(calculationId: number): any {
    return this.httpClient.get(`${routes.calculationFlowData}/${calculationId}`);
  }
}
