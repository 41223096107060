<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>HEADER.ABOUT</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
  <div class="row mt-2">
    <div class="col-12 col-sm-12">
      <h6 class="my-auto" translate>ABOUT.VERSION_INFORMATION</h6>
    </div>
    <ng-container *ngIf="aboutData && aboutData.length">
      <ng-container *ngFor="let items of aboutData">
        <div class="col-12 col-sm-12 py-0 d-flex">
          <p class="my-auto">{{ items.name ? items.name : 'N/A' }}</p>
          : &nbsp;
          <p class="my-auto">{{ items?.version ? items.version : 'N/A' }}</p>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!(aboutData && aboutData.length)">
      <!-- <div class="col-12 col-sm-12">
          <h6 class="my-auto" translate>ABOUT.NO_DATA</h6>
        </div> -->
      <ng-container *ngFor="let items of loaderCount">
        <div class="my-1">
          <p-skeleton></p-skeleton>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="row mt-3">
    <div class="col-12 col-sm-12">
      <h6 class="my-auto" translate>ABOUT.AMASOL_SUPPORT</h6>
    </div>
    <div class="col-12 col-sm-12 py-0 d-flex">
      <p class="my-auto" translate>ABOUT.EMAIL</p>
      : &nbsp;
      <p class="my-auto">support@amasol.de</p>
    </div>
    <div class="col-12 col-sm-12 py-0 d-flex">
      <p class="my-auto" translate>ABOUT.PHONE</p>
      : &nbsp;
      <p class="my-auto">+49 89 1894743-55</p>
    </div>
    <div class="col-12 col-sm-12 py-0 mt-2">
      <p class="mb-1">amasol GmbH</p>
      <p class="mb-1">Claudius-Keller-Straße 3 B</p>
      <p class="my-auto">81669 München, Germany</p>
    </div>
    <div class="col-12 col-sm-12">
      <p class="small" translate>ABOUT.NOTE_INFORMATION</p>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-6 col-sm-6">
      <button
        pButton
        type="button"
        label="{{ 'ABOUT.CLOSE' | translate }}"
        class="p-button-outlined"
        style="width: 110px"
        (click)="closeAboutSidebar()"
      ></button>
    </div>
  </div>
</div>
