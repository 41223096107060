import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-toggle',
  templateUrl: './language-toggle.component.html',
  styleUrls: ['./language-toggle.component.scss'],
})
export class LanguageToggleComponent implements OnInit {
  lang = '';

  languages: any = [
    {
      label: 'English - EN',
      value: 'en-US',
      id: 'SEL_ENGLISH',
      command: () => {
        this.switch('en-US');
      },
    },
    {
      label: 'German - DE',
      value: 'de-DE',
      id: 'SEL_GERMAN',
      command: () => {
        this.switch('de-DE');
      },
    },
  ];

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.setDefaultLang('en');

    // eslint-disable-next-line no-unused-expressions
    this.translateService.currentLang === 'en-US' ? (this.lang = 'en') : (this.lang = 'de');

    // change lang on language change
    this.translateService.onLangChange.subscribe((e: any) => {
      this.lang = e.lang;
    });
  }

  switch(value: string): void {
    this.translateService.use(value);
  }
}
