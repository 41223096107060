import { TreeNode } from 'primeng/api';

export interface Metrics<T = any> extends TreeNode<T> {
  levelName?: string;
  countOfChildren?: number;
  childLevelName?: string;
}

export interface ContractDetails {
  action?: string;
  actionValue?: string;
  attributes?: [];
  contractstatus?: string;
  contractstatusValue?: string;
  coverage?: string;
  coverageValue?: string;
  name?: string;
  nameValue?: string;
  customer?: string;
  customerValue?: string;
  provider?: string;
  providerValue?: string;
  id?: number;
  idValue?: number;
}
export interface ContractVersion {
  version: string;
  startDate: string;
  endDate: string;
  status: string;
  statement: string;
  contractVersionId: number;
}
