import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { GridOptions, ITooltipParams, MenuItemDef } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { Observable } from 'rxjs';

import { CustomNoRowsOverlayComponent } from './rendererComponent/custom-no-rows-overlay/custom-no-rows-overlay.component';
import { CellRendererService } from '@app/core/resources/cell-renderer.service';
import { FilterParamsService } from '@app/core/resources/filter-params.service';
import { FilterTextWhitespacesService } from '@app/core/resources/filter-text-whitespaces.service';
import { FilterTypeService } from '@app/core/resources/filter-type.service';
import { PaginationAggridFlexService } from '@app/core/resources/pagination-aggrid-flex.service';
import { ColumnDef, GroupedData } from '@app/report/interface/report-config';
import { ReportService } from '@app/report/services/report.service';
import { RowSpanHelperService } from '@app/report/services/row-span-helper.service';
import { ComplianceReportService } from '@app/slm/services/compliance-report.service';

@Component({
  selector: 'app-compliance-report',
  templateUrl: './compliance-report.component.html',
  styleUrls: ['./compliance-report.component.scss'],
})
export class ComplianceReportComponent implements OnInit {
  @ViewChild('agGrid', { read: ElementRef }) gridElementRef!: ElementRef;

  reportId!: number;

  headers: any = [];

  reportData: any = [];

  actualValuesMap: any;

  grid!: GridApi;

  gridOptions!: GridOptions;

  haveFilters = true;

  noRecordsMsg!: string;

  isUpload = false;

  isAddEnabled = false;

  isFirstTimeCall = false;

  constructor(
    private complianceService: ComplianceReportService,
    private filterTypeService: FilterTypeService,
    private filterParams: FilterParamsService, // private iconLookup: IconLookupService
    private filterTextWhitespacesService: FilterTextWhitespacesService,
    private paginationService: PaginationAggridFlexService,
    private cellRendererService: CellRendererService,
    private rowSpanHelperService: RowSpanHelperService,
    private reportService: ReportService
  ) {
    this.gridOptions = {
      alwaysMultiSort: true,
      headerHeight: 30,
      groupHeaderHeight: 40,
      rowHeight: 30,
      defaultColDef: { resizable: true },
      rowSelection: 'multiple',
      suppressRowClickSelection: true, // Prevent row click from selecting directly
      suppressMultiRangeSelection: true,
      getContextMenuItems: data => {
        return this.getContextMenuItems(data);
      },
      noRowsOverlayComponent: CustomNoRowsOverlayComponent,
      noRowsOverlayComponentParams: { noRowsMessageFunc: () => this.noRecordsMsg },
      preventDefaultOnContextMenu: true, // to prevent the browser default context menu,
      getMainMenuItems: () => {
        return this.getMainMenuItems();
      },
      suppressAggFuncInHeader: true, // headers won't include the aggFunc name (eg-sum)
      pagination: true,
      paginationPageSize: 50,
      paginationPageSizeSelector: [50, 100, 200],
      maxBlocksInCache: 0,
      cacheBlockSize: 50,
      serverSidePivotResultFieldSeparator: '_',
      pivotMode: false,

      rowModelType: 'serverSide',
      rowBuffer: 200,

      columnDefs: this.headers,
      onFirstDataRendered(params) {
        params.api.setGridOption('defaultColDef', { autoHeight: true });
      },
    };
  }

  ngOnInit(): void {
    this.getReportType();
  }

  getReportType(): void {
    this.complianceService.getReportType('compliance').subscribe((res: any) => {
      if (res?.length) {
        this.reportId = res[0].id;
        this.getHeadersData();
      } else {
        this.noRecordsMsg = 'Compliance report setup is not done yet';
        this.grid.showNoRowsOverlay();
      }
    });
  }

  getHeadersData(): void {
    this.headers = [];
    const headers = this.complianceService.getReportHeaders(this.reportId) as Observable<any>;

    headers.subscribe((res: any) => {
      this.headers = res.map((item: any) => this.headerFormulate(item));

      this.isFirstTimeCall = true;
      // Set the grid datasource after processing headers
      this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());
    });
  }

  headerFormulate(header: any): any {
    const shouldHide = header.hidden || header.hide;
    const headerObj: ColumnDef | any = {
      id: header.id,
      field: header.fieldName ? header.fieldName.toLowerCase() : header.field.toLowerCase(),
      ...(header.groupRowsBy && {
        rowSpan: (params: any) => {
          return params.data[
            header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`
          ] &&
            params.data[header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`]
              .rowSpan > 0
            ? params.data[header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`]
                .rowSpan
            : 1;
        },
        cellClassRules: {
          'cell-span': (params: any) => {
            return (
              params.data[header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`] &&
              params.data[header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`]
                .rowSpan > 0
            );
          },
          'cell-group': 'true',
          // 'cont-span': (params: any) => {
          //   const fieldName = header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`;
          //   const columnName = `isDuplicate${fieldName}`;

          //   return !!(!!params.data[columnName] && params.data[columnName] === true);
          // },
        },
        cellClass: (params: any) => {
          return params.data[
            header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`
          ] &&
            params.data[header.fieldName ? `${header.fieldName.toLowerCase()}` : `${header.field.toLowerCase()}`]
              .rowSpan > 0
            ? 'cell-span'
            : 'one-cell';
        },
      }),
      // tooltipValueGetter: (p: ITooltipParams) => p.value,
      minWidth: header.minWidth,
      maxWidth: header.maxWidth,
      wrapText: header.textWrapping,
      headerName: header.displayName ? header.displayName : header.headerName,
      sortable: header.sortable,
      autoHeight: header.groupRowsBy === false,
      ...(header.groupRowsBy && { tooltipValueGetter: (p: ITooltipParams) => p.value }),
      sort: header.sortOrder ? header.sortDirection.toLowerCase() : header.sort,
      initialSortIndex: header.sortOrder ? header.sortOrder : header.initialSortIndex,
      filter: header.filterable ? this.filterTypeService.getMeFilterType(header.fieldType) : header.filter,
      filterParams: header.filterable ? this.filterParams.getMeFilterParams(header.fieldType) : header.filterParams,
      cellRenderer: (rowData: any) => {
        return this.cellRendererService.reportCellRenderer(rowData, true);
      },
      enableSpan: header.groupRowsBy,
      enableRowGroup: false, // header.enableRowGroup,
      rowGroup: false, // header.groupRowsBy && header.enablePivot,
      hide: shouldHide,
      enableValue: header.enableValue,
      aggFunc: header.aggFunction,
      dataTypeFormatId: header.dataTypeFormatId,
      enablePivot: false, // header.enablePivot,
      pivot: false, // header.groupColumnsBy,
    };

    return headerObj;
  }

  transformRowGroupInfo(rowGroupInfo: any): any {
    // Create a mapping from fieldName to an object containing id, fieldName, and aggFunc from headers
    const fieldToIdMap = this.headers.reduce((accumulator: any, header: any) => {
      accumulator[header.field] = {
        id: header.id,
        fieldName: header.field,
        aggFunction: header.aggFunc,
        formatId: header.dataTypeFormatId,
      };

      return accumulator; // Return the accumulator object for the next iteration
    }, {});

    // Transform groupKeys by mapping each key to its corresponding actual value
    const groupKeys = this.transformGroupKeys(rowGroupInfo.groupKeys, rowGroupInfo.rowGroupCols);

    // Transform rowGroupCols by mapping each column to its corresponding id and fieldName
    const groupByRow = rowGroupInfo.rowGroupCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      // If mapping exists, replace the id and fieldName, otherwise, return the column unchanged
      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform valueCols by mapping each column to its corresponding id and fieldName, and override aggFunc if available
    const valueCols = rowGroupInfo.valueCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? {
            ...col,
            id: mappedField.id,
            fieldName: mappedField.fieldName,
            aggFunction: mappedField.aggFunc || col.aggFunc,
            formatId: mappedField.formatId,
          }
        : col;
    });

    // Transform pivotCols
    const pivotColumns = rowGroupInfo.pivotCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Create a new object with transformed properties
    return {
      ...rowGroupInfo,
      groupKeys,
      groupByRow,
      valueCols,
      pivotColumns,
    };
  }

  transformGroupKeys(groupKeys: any[], rowGroupCols: any[]): any[] {
    return groupKeys.map((key: any, index: number) => {
      const { field } = rowGroupCols[index];
      const actualValueKey = `${field}Value`;
      // Find the corresponding row data entry that contains the actual value
      const actualValue = this.findActualValueForGroupKey(key, actualValueKey);

      return actualValue !== undefined ? actualValue : key;
    });
  }

  findActualValueForGroupKey(formattedValue: any, actualValueKey: string): any {
    // Use Array.prototype.find to search through the reportData and find the actual value
    const rowInfo = this.actualValuesMap.find((row: any) => row[actualValueKey] !== undefined);

    return rowInfo ? rowInfo[actualValueKey] : undefined;
  }

  onGridReady(event: any): void {
    this.grid = event.api;
  }

  getGridDataFromAPI(): any {
    return {
      getRows: (agParams: any) => {
        const params: any = {
          offset: agParams.request.startRow,
          limit: agParams.request.endRow - agParams.request.startRow,
          sort: this.getMeSorted(agParams.request.sortModel),
        };

        // updating cache to be in sync with page size
        this.grid.setGridOption('cacheBlockSize', this.grid.paginationGetPageSize());

        this.complianceService
          .getReportData(this.reportId, this.getMeFiltered(agParams.request.filterModel, agParams.request), params)
          .subscribe((res: any) => {
            this.reportData = res.elements;
            if (!res.elements.length) {
              this.noRecordsMsg = 'No records found';
              this.grid.showNoRowsOverlay();
            } else {
              this.grid.hideOverlay();
            }

            const tableData: any = res.elements.map((row: any) => {
              const rowDataEntry: any = {};
              let attributes: any = [];

              const hiddenHeaders = this.headers
                .filter((head: any) => {
                  return head.hide;
                })
                .map((m: any) => String(m.field).toLowerCase());

              // Group headers logic
              if (this.isFirstTimeCall) {
                if (res && res.reportColumnRoot && res.reportColumnRoot.length > 0) {
                  const groupedData: GroupedData = res.reportColumnRoot.reduce((acc: GroupedData, item: any) => {
                    if (item.groupName) {
                      const { groupName } = item;

                      if (res.elements[0].reportColumnData && res.elements[0].reportColumnData.length > 0) {
                        const formatedData = res.elements[0].reportColumnData.find((data: any) => {
                          return (
                            data.fieldName &&
                            data.fieldName.substring(0, data.fieldName.indexOf('_')) === item.fieldName &&
                            String(data.fieldName.split('_')[1]).toLowerCase() === String(item.groupName).toLowerCase()
                          );
                        });

                        if (formatedData) {
                          // eslint-disable-next-line no-param-reassign
                          item.fieldName = formatedData.fieldName;
                        }
                      }

                      let header;

                      if (
                        hiddenHeaders &&
                        hiddenHeaders.length > 0 &&
                        item.groupName &&
                        !hiddenHeaders.includes(String(item.groupName).toLowerCase())
                      ) {
                        header = this.headerFormulate(item);
                      }

                      if (!acc[groupName]) {
                        acc[groupName] = [];
                      }

                      if (header) {
                        acc[groupName].push(header);
                      }
                    }

                    return acc;
                  }, {} as GroupedData);

                  // Combine headers from API
                  this.headers = this.headers.reduce((acc: any, header: any) => {
                    if (groupedData[header.headerName]) {
                      acc.push({
                        ...header,
                        children: groupedData[header.headerName],
                      });
                    } else {
                      acc.push(header);
                    }

                    return acc;
                  }, []);
                }
                this.isFirstTimeCall = false;
              }

              // Construct row data
              row.reportColumnData.forEach((colData: any) => {
                const fieldName = this.grid.isPivotMode() ? colData.fieldName : colData.fieldName.toLowerCase();

                rowDataEntry[fieldName] = colData.formattedValue; // Store formatted value
                rowDataEntry[`${fieldName}Value`] = colData.value; // Store actual value

                // Pass field identifier in attributes
                if (colData.reportAttributeDtos) {
                  const newAttr = colData.reportAttributeDtos?.map((v: any) => ({
                    ...v,
                    field: colData.fieldName.toLowerCase(),
                  }));

                  attributes = [...attributes, ...newAttr];
                }
                rowDataEntry.attributes = attributes;
              });

              return rowDataEntry;
            });

            this.headers.forEach((_header: any) => {
              // eslint-disable-next-line no-param-reassign
              _header.field = _header.field.replace('.value', '');
            });

            const headers = this.headers.filter((f: any) => {
              return !f.hide;
            });
            const makedData = this.rowSpanHelperService.createData(headers, tableData);

            tableData.forEach((make: any, index: number) => {
              // eslint-disable-next-line no-param-reassign
              make = Object.assign(make, makedData[index]);
            });

            this.headers.forEach((header: any) => {
              if (header && header.field && !header.hide) {
                // eslint-disable-next-line no-param-reassign
                header.field = `${header.field}.value`;
              }
            });

            this.actualValuesMap = tableData;

            agParams.success({
              rowData: tableData,
              rowCount: res.totalElements,
            });

            // Auto-resize all columns by default
            this.grid.autoSizeAllColumns();

            // Workaround to show the actual number of rows for a given page
            this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef);
          });
      },
    };
  }

  getMeSorted(dirtyData: any): any {
    if (dirtyData.length > 0) {
      const sort: any = [];

      for (let i = 0; i <= dirtyData.length - 1; i -= -1) {
        sort.push(`${dirtyData[i].colId},${dirtyData[i].sort}`);
      }

      return sort;
    }

    return '';
  }

  // prevent pagination updates on sorting when no records are available
  onSortChange(): void {
    if (!this.reportData.length) {
      this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef, true);
    }
  }

  getMeFiltered(filterData: any, rowGroupInfo: any): any {
    const sqlQueries: any = [];

    Object.keys(filterData).forEach(key => {
      const filter = filterData[key];
      const conditions: any = [];

      const appliedFilterData: any = {
        fieldName: key,
        filterType: filter.filterType,
      };

      if (filter.operator) {
        appliedFilterData.operator = filter.operator;

        filter.conditions.forEach((item: any) => {
          const filterVal: any = {
            type: item.type,
            start: item.filterType === 'date' ? item.dateFrom : item.filter,
          };

          if (item.type === 'inRange') {
            filterVal.end = item.filterType === 'date' ? item.dateTo : item.filterTo;
          }
          conditions.push(filterVal);
        });
      } else {
        const filterVal: any = {
          type: filter.type,
          start: filter.filterType === 'date' ? filter.dateFrom : filter.filter,
        };

        if (filter.type === 'inRange') {
          filterVal.end = filter.filterType === 'date' ? filter.dateTo : filter.filterTo;
        }
        conditions.push(filterVal);
      }

      appliedFilterData.conditions = conditions;

      sqlQueries.push(appliedFilterData);
    });

    this.haveFilters = Object.values(this.grid.getFilterModel()).length > 0;

    const filterConditions = sqlQueries;
    const payload: any = filterConditions?.length ? { filterConditions } : {};

    const agParamsRequestData: any = this.transformRowGroupInfo(rowGroupInfo);
    // Creating a new object and merging the properties of payload and rowgroupPayload into it
    const mergedPayload = { ...payload, ...agParamsRequestData };

    // This has been done as the key for the start and end row different from api end point and key does not serve it's purpose
    delete mergedPayload.startRow;
    delete mergedPayload.endRow;

    return mergedPayload;
  }

  // handling if filter only contains whitespace
  onFilterModified(e: any): void {
    this.filterTextWhitespacesService.textWhitespaceTrim(e, this.grid);
  }

  // custom main column header menu Items
  getMainMenuItems(): [] {
    const customResetCol: any = [
      'pinSubMenu',
      'separator',
      {
        name: 'Reset Columns',
        action: () => {
          this.grid.autoSizeAllColumns();
          this.grid.resetColumnState();
        },
      },
    ];

    return customResetCol;
  }

  clearGrid(): void {
    this.grid.setFilterModel(null);
  }

  onCellClick(event: any): void {
    if (event.data.attributes.length) {
      event.data.attributes.forEach((property: any) => {
        if (event.column.colId === property.field) {
          if (property.name === 'Drill to Upload') {
            this.isUpload = !this.isUpload;
            this.reportService.getUploadViewData({ data: event.data, property });
          }

          if (property.name === 'Drill To Webform Add' || property.name === 'Drill To Webform Edit') {
            this.webformRightsidebar(event.data, property);
          }
        }
      });
    }
  }

  webformRightsidebar(params: any, property: any): void {
    this.isAddEnabled = true;
    this.reportService.getUploadViewData({ data: params, property, header: 'Compliance' });
  }

  getContextMenuItems(params: any): [] {
    const rowData = params.node.data;
    const menuItems: any = [];

    if (rowData.attributes.length) {
      rowData.attributes.forEach((property: any) => {
        if (params.column.colId === property.field) {
          if (property.name === 'Drill To Webform Add' || property.name === 'Drill To Webform Edit') {
            menuItems.push(this.getMenuItems(rowData, property));
          }
        }
      });
    }

    return menuItems;
  }

  getMenuItems(event: any, formProperty: any): string | MenuItemDef {
    const editCell = 'Drill To Webform Edit';

    const result: string | MenuItemDef = {
      name: formProperty.name === editCell ? 'Edit Webform' : 'Add WebForm',
      icon:
        formProperty.name === editCell
          ? '<i class="pi pi-file-edit p-1 align-middlee"></i>'
          : '<i class="pi pi-plus-circle p-1 align-middlee"></i>',
      cssClasses: formProperty.name === editCell ? ['fw-bold', 'cursor-pointer'] : ['fw-bold', 'cursor-pointer'],
      action: () => {
        this.webformRightsidebar(event, formProperty);
      },
    };

    return result;
  }
}
