<div class="d-flex justify-content-start">
  <button
    class="p-link"
    pTooltip="{{ 'MODULES.METRICS.DETAILS' | translate }}"
    tooltipPosition="top"
    (click)="getViewMetricConnectorsByID(true)"
  >
    <img src="assets/icons/table/view.svg" alt="view icon" class="mb-1" />
  </button>
  <button class="p-link ml-2" (click)="menu.toggle($event)" disabled="true">
    <i class="pi pi-ellipsis-v text-primary"></i>
  </button>
  <p-menu #menu [popup]="true" [model]="actionsMenu()" appendTo="body"></p-menu>
</div>
