<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline">{{ metricConnector?.name }}</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
  <div class="row">
    <!--Source data-->
    <div class="col-3 col-sm-3" translate>MODULES.VIEW_METRICS.SOURCE_DATA</div>
    <div class="col-9 col-sm-9">
      {{ viewData?.calculationName ? viewData.calculationName : 'NA' }}
    </div>

    <!--Parameter list-->
    <div class="col-12 col-sm-12">
      {{ 'MODULES.VIEW_METRICS.PARAMETER_LIST' | translate }}
    </div>

    <div class="col-12 col-sm-12">
      <p-table
        styleClass="p-datatable-sm p-datatable-gridlines"
        [value]="viewData.parameters ? viewData.parameters : noDataList"
      >
        <ng-template pTemplate="header">
          <tr>
            <th translate>MODULES.METRIC_CONNECTOR.PARAMETER_NAME</th>
            <th translate>MODULES.VIEW_METRICS.TYPE</th>
            <th translate>MODULES.VIEW_METRICS.COLUMN_NAME</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-view>
          <tr>
            <td>{{ view.paramName ? view.paramName : 'N/A' }}</td>
            <td class="view-metric-catalog-text-info">
              {{
                view.calculationColumnDataTypeName
                  ? view.calculationColumnDataTypeName
                  : 'N/A'
              }}
            </td>
            <td>
              {{
                view.calculationColumnName ? view.calculationColumnName : 'N/A'
              }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <!--Result to compare-->
    <div class="col-3 col-sm-3" translate>
      MODULES.VIEW_METRICS.RESULT_COMPARE
    </div>
    <div class="col-9 col-sm-9">
      {{
        viewData?.calculationColumnName
          ? viewData?.calculationColumnName
          : 'N/A'
      }}
    </div>

    <!--Status-->
    <div class="col-3 col-sm-3" translate>MODULES.STATUS.STATUS</div>
    <div class="col-9 col-sm-9">
      <span [ngClass]="viewData.isPublished ? 'success-chip' : 'danger-chip'">
        {{ viewData?.isPublished ? 'Published' : 'Not Published' }}
      </span>
    </div>

    <!-- Used by Metrics -->
    <div class="col-3 col-sm-3" translate>
      MODULES.VIEW_METRICS.USED_BY_METRICS
    </div>

    <div class="col-9 col-sm-9">
      <span *ngIf="viewData?.countOfMetrics > 0">
        {{ viewData?.countOfMetrics }}
        {{ 'MODULES.SLM.METRICS' | translate }}
      </span>
      <span *ngIf="!viewData?.countOfMetrics">
        0 {{ 'MODULES.METRICS.METRIC' | translate }}
      </span>
      <!-- <span
            *ngIf="viewData?.countOfMetrics > 0"
            class="view-metric-catalog-default-color view-metric-catalog-underline view-metric-catalog-cursor"
            translate
          >
            MODULES.VIEW_METRICS.SHOW_ALL
          </span> -->
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-6 col-sm-6">
      <button
        pButton
        type="button"
        label="{{ 'ABOUT.CLOSE' | translate }}"
        class="p-button-outlined"
        style="width: 110px"
        (click)="closeViewMetric()"
      ></button>
    </div>
  </div>
</div>
