import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { GridOptions, IServerSideSelectionState, IsRowSelectable } from 'ag-grid-community';
import { GridApi } from 'ag-grid-enterprise';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';

import { CellRendererService } from '@app/core/resources/cell-renderer.service';
import { FilterParamsService } from '@app/core/resources/filter-params.service';
import { FilterTextWhitespacesService } from '@app/core/resources/filter-text-whitespaces.service';
import { FilterTypeService } from '@app/core/resources/filter-type.service';
import { PaginationAggridFlexService } from '@app/core/resources/pagination-aggrid-flex.service';
import { ValidationService } from '@app/core/services/validation.service';
import { ITreeConfig } from '@app/report/interface/column.config';
import { UdfComponent } from '@app/shared/components/udf/udf.component';
import { CheckboxRendererComponent } from '@app/slm/components/duplicate-contract/rendererComponent/checkboxRenderer';
import { DateRendererComponent } from '@app/slm/components/duplicate-contract/rendererComponent/dateRenderer';
import { SelectRendererComponent } from '@app/slm/components/duplicate-contract/rendererComponent/selectRenderer';
import { Contract, ObjectFieldData } from '@app/slm/interface/view-contrcat';
import { ContractService } from '@app/slm/services/contract.service';
import { OrganizationService } from '@app/slm/services/organization.service';

@Component({
  selector: 'app-duplicate-contract',
  templateUrl: './duplicate-contract.component.html',
  styleUrls: ['./duplicate-contract.component.scss'],
})
export class DuplicateContractComponent implements OnInit {
  @ViewChild('agGrid', { read: ElementRef }) gridElementRef!: ElementRef;

  @ViewChild(UdfComponent) udfComponent!: UdfComponent;

  @Output() closeClicked = new EventEmitter<boolean>();

  @Input() viewContrcatData!: Contract;

  UDFList: ObjectFieldData[] = [];

  contractForm: FormGroup = this.initForm();

  minStartDate = new Date('01-01-1901');

  maxEndDate = new Date('12-31-9999');

  minEndDate!: Date;

  organizationData: any = [];

  canSeeTreeView = false;

  errorMsgList: any = [];

  selectedNodes!: any;

  selectedMetrics: any = [];

  customFieldData: any; // Need to defeine interface for this

  contractUDFData: any;

  // table variable
  headers: any = [];

  grid!: GridApi;

  gridOptions: GridOptions;

  haveFilters = true;

  use60Percent!: boolean;

  metricsTableData: any = []; // Chnage this variable later to adopt as  per our table

  dropdownOptions: any[] = [];

  updatedMetrics: Array<{
    metricId: string;
    metricVersionDtoList: Array<{
      metricVersionId: number | string;
      startDate: string | Date;
      endDate: string | Date;
      status: string;
    }>;
  }> = [];

  selectedValue: any; // This will hold the selected value

  isDropdownEnabled = false; // Initialize dropdown as disabled

  // To keep track of selected nodes
  private previouslySelectedNodes: Set<string> = new Set();

  private bulkSelectionInitiated = false;

  constructor(
    private formBuilder: FormBuilder,
    private organizationService: OrganizationService,
    private contractService: ContractService,
    private filterTextWhitespacesService: FilterTextWhitespacesService,
    private filterTypeService: FilterTypeService,
    private filterParams: FilterParamsService,
    private paginationService: PaginationAggridFlexService,
    private cellRendererService: CellRendererService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.gridOptions = {
      alwaysMultiSort: true,
      headerHeight: 30,
      groupHeaderHeight: 40,
      rowHeight: 30,
      suppressContextMenu: true,
      defaultColDef: { resizable: true },
      rowSelection: 'multiple',
      suppressRowClickSelection: true, // Prevent row click from selecting directly
      suppressMultiRangeSelection: true,
      overlayNoRowsTemplate: '<span class="ag-overlay-no-rows-center">No records found</span>',
      preventDefaultOnContextMenu: true, // to prevent the browser default context menu,
      suppressAggFuncInHeader: true, // headers won't include the aggFunc name (eg-sum)
      pagination: true,
      paginationPageSize: 50,
      paginationPageSizeSelector: [50, 100, 200],
      maxBlocksInCache: 0,
      cacheBlockSize: 50,
      serverSidePivotResultFieldSeparator: '_',
      pivotMode: false,

      rowModelType: 'serverSide',
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        hiddenByDefault: true,
      },
      columnDefs: this.headers,

      onFirstDataRendered(params) {
        params.api.setGridOption('defaultColDef', { autoHeight: true });
      },

      getMainMenuItems: () => {
        return this.getMainMenuItems();
      }, // custom main column header menu
      isRowSelectable: this.isRowSelectable.bind(this), // Ensure only rows with 'Revoked' status are excluded

      onRowSelected: this.onRowSelected.bind(this), // Handle row selections
      rowClassRules: {
        'row-disabled': params => params.data && params.data.metricversionstatus === 'Revoked',
      },
    };
    this.translate
      .get(['MODULES.CONTRACTS.DRAFT', 'MODULES.CONTRACTS.PUBLISHED', 'MODULES.CONTRACTS.REVOKED'])
      .subscribe(translations => {
        this.dropdownOptions = [
          {
            label: translations['MODULES.CONTRACTS.DRAFT'],
            value: 'Draft',
            icon: 'pi pi-pencil',
          },
          {
            label: translations['MODULES.CONTRACTS.PUBLISHED'],
            value: 'Published',
            icon: 'pi pi-check',
          },
        ];
      });
  }

  ngOnInit(): void {
    this.getOrganizationDetails();
    if (this.viewContrcatData) {
      this.contractForm.get('id')?.patchValue(`${this.viewContrcatData.contractNo}_copy`);
      this.contractForm.get('contractName')?.patchValue(`Copy of ${this.viewContrcatData.contractName}`);
      this.contractForm
        .get('description')
        ?.patchValue(`${this.viewContrcatData.description ? this.viewContrcatData.description : ''}`);
      this.UDFList = this.viewContrcatData.objectFieldData || [];
    }

    // getting selected metric list
    this.contractService.selectedMetricList.subscribe((res: any) => {
      if (res) {
        this.selectedMetrics = res;
      }
    });

    this.contractService.isSidebarWidth$.subscribe((isWidth: boolean) => {
      if (isWidth) {
        this.use60Percent = isWidth;
      }
    });
  }

  nodeSelect(): void {
    const customer = this.contractForm.get('customerName')?.value?.businessId;
    const provider = this.contractForm.get('providerName')?.value?.businessId;

    this.contractForm.controls['providerName'].setErrors(null);
    if (customer && provider && customer === provider) {
      this.contractForm.controls['providerName'].setErrors({ invalid: true });
    }
    if (customer && !provider) {
      this.contractForm.controls['providerName'].setErrors({ required: true });
    }

    this.contractForm.controls['providerName'].markAsTouched();
  }

  onClear(str: 'PROVIDER' | 'CUSTOMER'): void {
    if (str === 'CUSTOMER') {
      this.contractForm.controls['customerName'].patchValue(null);
      this.contractForm.controls['customerName'].markAllAsTouched();
    } else {
      this.contractForm.controls['providerName'].patchValue(null);
      this.contractForm.controls['providerName'].markAllAsTouched();
    }

    this.organizationData.forEach((node: TreeNode<any>) => {
      this.expandRecursive(node, true);
      this.disableRecursive(node);
    });
  }

  saveAndCopyMetric(): void {
    if (this.contractForm.invalid || this.udfComponent.dynamicForm.invalid) {
      this.contractForm.markAllAsTouched();
    } else {
      this.validateContract(undefined, undefined, false);
    }
  }

  createPayload(): any {
    const startDate = this.datePipe.transform(this.contractForm.get('startDate')?.value, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.contractForm.get('endDate')?.value, 'yyyy-MM-dd');

    const basePayload = {
      contractNo: this.contractForm.get('id')?.value,
      name: this.contractForm.get('contractName')?.value,
      description: this.contractForm.get('description')?.value,
      customerId: this.contractForm.get('customerName')?.value?.businessId,
      providerId: this.contractForm.get('providerName')?.value?.businessId,
      versionName: this.contractForm.get('version')?.value,
      startDate: startDate ? new Date(startDate).toISOString() : '',
      endDate: endDate ? new Date(endDate).toISOString() : '',
      statement: this.contractForm.get('statement')?.value,
      metricCatalogIds: this.selectedMetrics?.metricCatalogIds,
      selectionStartDate: this.contractForm.get('selectionStartDate')?.value,
      selectionEndDate: this.contractForm.get('selectionEndDate')?.value,
    };

    const payload = {
      ...basePayload,
      ...(this.customFieldData && this.customFieldData.length > 0 ? { copyObjectFieldList: this.customFieldData } : {}),
      ...(this.updatedMetrics && this.updatedMetrics.length > 0 ? { copyMetricDtoList: this.updatedMetrics } : {}),
    };

    return payload;
  }

  /**
   * Validates the contract by checking for duplicates and determining whether the tree view should be visible.
   * If the tree view should not be visible and validation passes, a duplicate contract is saved.
   * If the validation passes and the tree view is visible, it fetches the necessary data for the grid.
   *
   * @param {boolean} [canSeeTreeView] - Optional parameter to specify if the tree view should be visible.
   *                                     Defaults to true if not provided.
   *
   * @returns {void} - This function does not return a value, but updates the tree view visibility and manages data fetching.
   */
  validateContract(event?: Event, canSeeTreeView?: boolean, showConfirmation = true): void {
    this.errorMsgList = [];
    const payload = this.createPayload();

    // If showConfirmation is true, show the confirmation dialog
    if (showConfirmation) {
      this.confirmationService.confirm({
        target: event?.target ? event.target : undefined,
        icon: 'pi pi-exclamation-triangle',
        message: this.translate.instant('MODULES.CONTRACTS.CONFIRM_COPY'),
        acceptLabel: this.translate.instant('MODULES.CONTRACTS.YES'),
        rejectLabel: this.translate.instant('MODULES.CONTRACTS.NO'),
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.executeValidation(payload, canSeeTreeView);
        },
      });
    } else {
      // Directly call the validation without confirmation
      this.executeValidation(payload, canSeeTreeView);
    }
  }

  executeValidation(payload: any, canSeeTreeView?: boolean): void {
    this.contractService.validateDupContract(this.viewContrcatData.id, payload).subscribe(
      (res: any) => {
        if (res) {
          this.canSeeTreeView = canSeeTreeView !== undefined && canSeeTreeView !== null ? canSeeTreeView : true;

          if (!this.canSeeTreeView) {
            this.canSeeTreeView = true;
            this.saveDuplicateContract(this.viewContrcatData.id, payload);
          } else {
            this.contractService.getSidebarWidth(false);
            this.getData();
          }
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          const errorList: any = [];

          err.forEach((msg: any) => {
            errorList.push(msg.message);
          });
          this.errorMsgList = errorList;
        }
      }
    );
  }

  saveDuplicateContract(contractId: number, payload: any): void {
    this.contractService.saveDuplicateContract(contractId, payload).subscribe(
      (response: any) => {
        if (response) {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translate.instant('MODULES.CONTRACTS.DUPLICATE_SUCCESS', {
              contract: response.contractName,
              version: response.status,
            }),
          });
        }
        this.contractService.getCreatedContract(response);
        // Optionally close the sidebar after successful save
        this.closeContract();
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          const errorList: any = [];

          err.forEach((msg: any) => {
            errorList.push(msg.message);
          });
          this.errorMsgList = errorList;
        }
      }
    );
  }

  onSelectStartDate(date: any): void {
    this.minEndDate = new Date();
    this.minEndDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    this.contractForm.get('endDate')?.reset();
  }

  onFormDataChanged(formData: any): void {
    /**
     * Check if the field has either 'value' or 'valueSetKey'
     * and ensure that null/undefined are properly handled.
     */

    if (formData.metricObjectFields && formData.metricObjectFields.length > 0) {
      this.customFieldData = formData.metricObjectFields.filter((obj: any) => {
        // Check for value existence: value may be number, string, or present but null/undefined
        const hasValue =
          Object.prototype.hasOwnProperty.call(obj, 'value') && obj.value !== null && obj.value !== undefined;
        const hasValueSetKey =
          Object.prototype.hasOwnProperty.call(obj, 'valueSetKey') &&
          obj.valueSetKey !== null &&
          obj.valueSetKey !== undefined;

        return hasValue || hasValueSetKey;
      });
    }
  }

  closeContract(): void {
    this.closeClicked.emit();
  }

  getMainMenuItems(): [] {
    const customResetCol: any = [
      'pinSubMenu',
      'separator',
      {
        name: 'Reset Columns',
        action: () => {
          this.grid.autoSizeAllColumns();
          this.grid.resetColumnState();
        },
      },
    ];

    return customResetCol;
  }

  // table informaiton and intilastion
  onGridReady(event: any): void {
    this.grid = event.api;
  }

  /**
   * Fetches and processes metric version headers for the contract report.
   * It retrieves the headers via an API call, filters out hidden or grouped columns,
   * maps the headers to determine the first visible column, and configures the grid's data source.
   *
   * @returns {void} - This function does not return a value but sets the processed headers and configures the grid's data source.
   */
  getData(): void {
    this.headers = [];
    const params = {
      reportIdentifier: 'Metric Versions Per Contract',
    };
    const headers = this.contractService.getMetricVersionHeader(params) as Observable<any>;

    headers.subscribe((res: any) => {
      // Check for valid headers
      if (!res || !Array.isArray(res)) {
        // return it no data
        return;
      }

      // Filter out hidden headers
      const visibleHeaders = res.filter((header: any) => !header.groupRowsBy && !header.hidden && !header.hide);

      // Find the index of the first visible header
      const firstVisibleIndex = visibleHeaders.length > 0 ? res.indexOf(visibleHeaders[0]) : -1;
      let isCheckboxRendered = false; // Initialize flag to track if checkbox is rendered

      // Map through headers and update isCheckboxRendered appropriately
      this.headers = res.map((item: any, index: number) => {
        // Determine if this is the first visible column
        const isFirstVisible = index === firstVisibleIndex;

        // Call headerFormulate, passing the isCheckboxRendered flag
        const { headerObj, isCheckboxRendered: newCheckboxState } = this.headerFormulate(
          item,
          index,
          isFirstVisible,
          isCheckboxRendered
        );

        // Update the flag after each column
        isCheckboxRendered = newCheckboxState;

        // Return only the header object
        return headerObj;
      });

      // Set the grid datasource after processing headers
      this.grid.setGridOption('serverSideDatasource', this.getGridDataFromAPI());
    });
  }

  /**
   * Formulates a header object with the appropriate structure for use in AG Grid.
   *
   * @param {Object} header - The header configuration object containing properties of a column.
   *   @param {number} header.id - A unique identifier for the header column. Used for referencing it in the grid or API.
   *   @param {string} header.fieldName - The name of the field this header represents, used for binding data to the column.
   *   @param {string} header.field - The name of the field to use if fieldName is not provided. Acts as a fallback field reference.
   *   @param {number} header.minWidth - The minimum width of the column. Sets how narrow the column can be.
   *   @param {number} header.maxWidth - The maximum width of the column. Limits how wide the column can expand.
   *   @param {boolean} header.textWrapping - Specifies whether the text in the column should wrap if it exceeds column width.
   *   @param {string} header.displayName - The user-friendly name to display in the column header. Optional if headerName is used.
   *   @param {string} header.headerName - The name displayed in the header if displayName is not available. A fallback for displayName.
   *   @param {boolean} header.sortable - Indicates whether the user can sort the column by clicking on the header.
   *   @param {string} header.sortOrder - Defines the order in which the column should be sorted initially (optional).
   *   @param {string} header.sortDirection - Specifies the sort direction ('asc' or 'desc') for the column (optional).
   *   @param {string} header.sort - Defines the sort direction if sortOrder is not available, acting as a fallback.
   *   @param {boolean} header.filterable - Specifies if the column can be filtered using a filter input box.
   *   @param {string} header.fieldType - Determines the type of field (e.g., string, number) to select the appropriate filter type.
   *   @param {boolean} header.groupRowsBy - Specifies whether rows should be grouped by this column's values.
   *   @param {number} header.dataTypeFormatId - An optional ID to determine the formatting type for this field.
   *   @param {boolean} header.hide - Specifies whether the column should be hidden from the grid by default.
   *
   * @param {number} index - The position of the column in the grid, used to help determine which column is visible.
   *
   * @param {boolean} isFirstVisible - A flag indicating whether this is the first visible column in the grid,
   *                                   used to determine if the checkbox should be rendered.
   *
   * @param {boolean} isCheckboxRendered - Tracks whether a checkbox has already been rendered for the first visible column.
   *                                       Defaults to false if not specified.
   *
   * @returns {Object} - The formulated header object with the necessary properties for AG Grid, along with a flag indicating
   *                     whether the checkbox was rendered.
   */

  headerFormulate(header: any, index: number, isFirstVisible: boolean, isCheckboxRendered = false): any {
    const shouldHide = header.groupRowsBy || header.hidden || header.hide;
    const isStatusField = header.fieldName.toLowerCase() === 'status';

    // Determine if the checkbox should be rendered in the first visible column
    const showCheckbox = isFirstVisible && !shouldHide && !isCheckboxRendered;

    const headerCheckboxSelection = showCheckbox ? true : undefined;

    // Dynamically choose the cellRenderer based on the field type and checkbox logic
    const cellRenderer = this.getCellRenderer({
      ...header,
      showCheckbox,
    });

    const headerObj: any = {
      id: header.id,
      field: header.fieldName ? header.fieldName.toLowerCase() : header.field.toLowerCase(),
      minWidth: header.minWidth,
      maxWidth: header.maxWidth,
      wrapText: header.textWrapping,
      headerName: header.displayName ? header.displayName : header.headerName, // Always show header name
      headerCheckboxSelection, // Only set header checkbox in the first visible column
      sortable: header.sortable,
      sort: header.sortOrder ? header.sortDirection.toLowerCase() : header.sort,
      initialSortIndex: header.sortOrder ? header.sortOrder : header.initialSortIndex,
      filter: header.filterable ? this.filterTypeService.getMeFilterType(header.fieldType) : header.filter,
      filterParams: header.filterable ? this.filterParams.getMeFilterParams(header.fieldType) : header.filterParams,

      cellRenderer, // Dynamically assign cellRenderer based on the logic in getCellRenderer

      hide: shouldHide,

      // Add cellEditorParams for specific logic related to status fields
      cellEditorParams: isStatusField
        ? (params: any) => {
            const initialStatus = params.data.metricversionstatus || '';
            const options = ['Draft', 'Published', 'Revoked'];

            return {
              values: options,
              searchType: 'match',
              allowTyping: true,
              filterList: true,
              highlightMatch: true,
              valueListMaxHeight: 220,
              cellHeight: 50,
              initialStatus,
            };
          }
        : {},

      showDisabledCheckboxes: showCheckbox, // Show disabled checkboxes if this is the first visible column

      // Add cellClassRules for styling the disabled checkboxes
      cellClassRules: {
        'ag-cell-disabled': (params: any) => {
          return params.data && params.data.metricversionstatus === 'Revoked';
        },
      },
    };

    // Return both the header object and the updated checkbox rendered flag
    return { headerObj, isCheckboxRendered: showCheckbox || isCheckboxRendered };
  }

  /**
   * Determines the appropriate cell renderer component based on the header field.
   * It supports rendering date fields, status dropdowns, and custom checkbox components.
   *
   * @param {Object} header - The header object that contains information about the column.
   * @param {string} header.fieldName - The name of the field for the column.
   * @param {boolean} [header.showCheckbox] - A flag to determine if the checkbox should be rendered for this column.
   *
   * @returns {any} - Returns the cell renderer component or a default renderer function based on the field type.
   */
  getCellRenderer(header: any): any {
    const isDateField = header.fieldName.toLowerCase() === 'startdate' || header.fieldName.toLowerCase() === 'enddate';
    const isStatusField = header.fieldName.toLowerCase() === 'status';

    // Check if the checkbox should be shown
    const shouldShowCheckbox = header.showCheckbox; // Passed from the headerFormulate logic

    if (isDateField) {
      return DateRendererComponent;
    }
    if (isStatusField) {
      return SelectRendererComponent;
    }

    // Conditionally return the checkbox renderer for the first visible column only
    if (shouldShowCheckbox) {
      return CheckboxRendererComponent; // Use the custom checkbox renderer
    }

    // Default renderer for other columns
    return (rowData: any) => {
      return this.cellRendererService.reportCellRenderer(rowData);
    };
  }

  isRowSelectable: IsRowSelectable = (params: any) => {
    // Only allow selection if the row's status is not "Revoked"
    return !!params.data && params.data.metricversionstatus !== 'Revoked';
  };

  getMeSorted(dirtyData: any): any {
    if (dirtyData.length > 0) {
      const sort: any = [];

      for (let i = 0; i <= dirtyData.length - 1; i -= -1) {
        sort.push(`${dirtyData[i].colId},${dirtyData[i].sort}`);
      }

      return sort;
    }

    return '';
  }

  // prevent pagination updates on sorting when no records are available
  onSortChange(): void {
    if (!this.metricsTableData.length) {
      this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef, true);
    }
  }

  getMeFiltered(filterData: any, rowGroupInfo: any): any {
    const sqlQueries: any = [];

    Object.keys(filterData).forEach(key => {
      const filter = filterData[key];
      const conditions: any = [];

      const appliedFilterData: any = {
        fieldName: key,
        filterType: filter.filterType,
      };

      if (filter.operator) {
        appliedFilterData.operator = filter.operator;

        filter.conditions.forEach((item: any) => {
          const filterVal: any = {
            type: item.type,
            start: item.filterType === 'date' ? item.dateFrom : item.filter,
          };

          if (item.type === 'inRange') {
            filterVal.end = item.filterType === 'date' ? item.dateTo : item.filterTo;
          }
          conditions.push(filterVal);
        });
      } else {
        const filterVal: any = {
          type: filter.type,
          start: filter.filterType === 'date' ? filter.dateFrom : filter.filter,
        };

        if (filter.type === 'inRange') {
          filterVal.end = filter.filterType === 'date' ? filter.dateTo : filter.filterTo;
        }
        conditions.push(filterVal);
      }

      appliedFilterData.conditions = conditions;

      sqlQueries.push(appliedFilterData);
    });

    this.haveFilters = Object.values(this.grid.getFilterModel()).length > 0;

    const filterConditions = sqlQueries;
    const payload: any = filterConditions?.length ? { filterConditions } : {};

    const agParamsRequestData: any = this.transformRowGroupInfo(rowGroupInfo);
    // Creating a new object and merging the properties of payload and rowgroupPayload into it
    const mergedPayload = { ...payload, ...agParamsRequestData };

    // This has been done as the key for the start and end row different from api end point and key does not serve it's purpose
    delete mergedPayload.startRow;
    delete mergedPayload.endRow;

    return mergedPayload;
  }

  // handling if filter only contains whitespace
  onFilterModified(e: any): void {
    this.filterTextWhitespacesService.textWhitespaceTrim(e, this.grid);
  }

  getGridDataFromAPI(): any {
    return {
      getRows: (agParams: any) => {
        const params: any = {
          offset: agParams.request.startRow,
          limit: agParams.request.endRow - agParams.request.startRow,
          sort: this.getMeSorted(agParams.request.sortModel),
        };

        // updating cache to be in sync with page size
        this.grid.setGridOption('cacheBlockSize', this.grid.paginationGetPageSize());

        this.contractService
          .getMetricVersionData(this.getMeFiltered(agParams.request.filterModel, agParams.request), params)
          .subscribe((res: any) => {
            this.metricsTableData = res.elements;
            if (!res.elements.length) {
              this.grid.showNoRowsOverlay();
            } else {
              this.grid.hideOverlay();
            }

            const tableData: any = res.elements.map((row: any) => {
              const rowDataEntry: any = {};
              let attributes: any = [];

              // Default value for 'status' if not provided
              const defaultStatus = 'Draft';
              const startDate = this.datePipe.transform(this.contractForm.get('startDate')?.value, 'yyyy-MM-dd');
              const endDate = this.datePipe.transform(this.contractForm.get('endDate')?.value, 'yyyy-MM-dd');
              const selectionStartDate = this.datePipe.transform(
                this.contractForm.get('selectionStartDate')?.value,
                'yyyy-MM-dd'
              );
              const selectionEndDate = this.datePipe.transform(
                this.contractForm.get('selectionEndDate')?.value,
                'yyyy-MM-dd'
              );

              // Construct row data
              row.reportColumnData.forEach((colData: any) => {
                const fieldName = colData.fieldName.toLowerCase();

                if (fieldName === 'status' || fieldName === 'metricversionstatus') {
                  if (fieldName === 'metricversionstatus') {
                    // Set metricversionstatus and its value if not already set
                    if (!rowDataEntry.metricversionstatus) {
                      rowDataEntry.metricversionstatus = colData.formattedValue || defaultStatus;
                      rowDataEntry.metricversionstatusValue = colData.value || defaultStatus;
                    }

                    // Set status if not already set, and prefer metricversionstatus
                    if (!rowDataEntry.status) {
                      rowDataEntry.status = rowDataEntry.metricversionstatus;
                      rowDataEntry.statusValue = rowDataEntry.metricversionstatusValue;
                    }
                  } else if (fieldName === 'status') {
                    // Set status if not already set
                    if (!rowDataEntry.status) {
                      rowDataEntry.status = colData.formattedValue || defaultStatus;
                      rowDataEntry.statusValue = colData.value || defaultStatus;
                    }
                  }
                } else {
                  rowDataEntry[fieldName] = colData.formattedValue; // Store formatted value for other fields
                  rowDataEntry[`${fieldName}Value`] = colData.value; // Store actual value for other fields
                }

                // Override startdate and enddate if they exist
                if ('startdate' in rowDataEntry) {
                  rowDataEntry.startdate = startDate;
                }
                if ('enddate' in rowDataEntry) {
                  rowDataEntry.enddate = endDate;
                }
                if ('selectionStartDate' in rowDataEntry) {
                  rowDataEntry.startdate = selectionStartDate;
                }
                if ('selectionEndDate' in rowDataEntry) {
                  rowDataEntry.enddate = selectionEndDate;
                }

                // Pass field identifier in attributes
                if (colData.reportAttributeDtos) {
                  const newAttr = colData.reportAttributeDtos?.map((v: any) => ({
                    ...v,
                    field: fieldName,
                  }));

                  attributes = [...attributes, ...newAttr];
                }
                rowDataEntry.attributes = attributes;
              });

              return rowDataEntry;
            });

            agParams.success({
              rowData: tableData,
              rowCount: res.totalElements,
            });

            // auto-resize all columns by default
            // check with grouping and spanning
            agParams.columnApi.autoSizeAllColumns();

            // Workaround to show the actual number of rows for a given page
            this.paginationService.setLbLastRowOnPage(this.grid, this.gridElementRef);
          });
      },
    };
  }

  /**
   * Transforms the rowGroupInfo object by mapping fields to their corresponding IDs and field names
   * based on the headers configuration. Also overrides the aggregation function (aggFunc) from the headers
   * where applicable.
   * Sets pivotMode to true if any column has pivot enabled.
   *
   * @param {Object} rowGroupInfo - The object containing information about row grouping, value columns,
   *                                pivot columns, and sorting configuration.
   * @param {Array} rowGroupInfo.groupByRow - Array of objects representing the columns used for row grouping.
   * @param {Array} rowGroupInfo.valueCols - Array of objects representing the columns used for values with
   *                                         aggregation functions.
   * @param {Array} rowGroupInfo.pivotColumns - Array of objects representing the columns used for pivoting.
   * @param {Array} rowGroupInfo.sortModel - Array of objects representing the sorting configuration for columns.
   *
   * @returns {Object} - The transformed rowGroupInfo object with updated IDs, field names, and aggregation
   *                     functions based on the headers configuration.
   */
  transformRowGroupInfo(rowGroupInfo: any): any {
    // Create a mapping from fieldName to an object containing id, fieldName, and aggFunc from headers
    const fieldToIdMap = this.headers.reduce((accumulator: any, header: any) => {
      accumulator[header.field] = {
        id: header.id,
        fieldName: header.field,
        aggFunction: header.aggFunc,
        formatId: header.dataTypeFormatId,
      };

      return accumulator; // Return the accumulator object for the next iteration
    }, {});

    // Transform groupKeys by mapping each key to its corresponding actual value

    // Transform rowGroupCols by mapping each column to its corresponding id and fieldName
    const groupByRow = rowGroupInfo.rowGroupCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      // If mapping exists, replace the id and fieldName, otherwise, return the column unchanged
      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform valueCols by mapping each column to its corresponding id and fieldName, and override aggFunc if available
    const valueCols = rowGroupInfo.valueCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? {
            ...col,
            id: mappedField.id,
            fieldName: mappedField.fieldName,
            aggFunction: mappedField.aggFunc || col.aggFunc,
            formatId: mappedField.formatId,
          }
        : col;
    });

    // Transform pivotCols
    const pivotColumns = rowGroupInfo.pivotCols.map((col: any) => {
      const mappedField = fieldToIdMap[col.field];

      return mappedField
        ? { ...col, id: mappedField.id, fieldName: mappedField.fieldName, formatId: mappedField.formatId }
        : col;
    });

    // Transform sortModel
    const sortModel = rowGroupInfo.sortModel.map((sort: any) => {
      const mappedField = fieldToIdMap[sort.colId.toLowerCase()];

      return mappedField ? { ...sort, colId: mappedField.fieldName } : sort;
    });

    const customParameters = [
      {
        parameter: 'CONTRACT_ID',
        parameterValues: [this.viewContrcatData.id],
      },
      {
        parameter: 'START_DATE',
        parameterValues: [
          this.contractForm.get('selectionStartDate')?.value
            ? this.datePipe.transform(this.contractForm.get('selectionStartDate')?.value, 'yyyy-MM-dd')
            : this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        ],
      },
      {
        parameter: 'END_DATE',
        parameterValues: [
          this.contractForm.get('selectionEndDate')?.value
            ? this.datePipe.transform(this.contractForm.get('selectionEndDate')?.value, 'yyyy-MM-dd')
            : this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        ],
      },
    ];

    // Create a new object with transformed properties
    return {
      ...rowGroupInfo,
      groupByRow,
      valueCols,
      pivotColumns,
      sortModel,
      customParameters,
    };
  }

  oncontractSelectStartDate(event: any): void {
    this.minEndDate = new Date();
    this.minEndDate = new Date(event.getFullYear(), event.getMonth(), event.getDate() + 1);
    this.contractForm.get('selectionEndDate')?.reset();
  }

  parseDateString(dateString: string): Date | null {
    const parsedDate = new Date(dateString);

    return this.isValidDate(parsedDate) ? parsedDate : null;
  }

  isValidDate(date: any): boolean {
    return date instanceof Date && !Number.isNaN(date.getTime());
  }

  clearGrid(): void {
    this.grid.setFilterModel(null);
  }

  /**
   *
   * @param state
   * @returns
   */

  // Type guard to check if selectionState is of type IServerSideSelectionState
  isSelectionStateWithSelectAll(state: any): state is IServerSideSelectionState {
    return 'selectAll' in state;
  }

  /**
   * Updates the status of selected rows in the grid based on user input from a dropdown or other input element.
   * Handles both individual row selection and "Select All" scenarios to update statuses efficiently.
   *
   * @param {any} [event] - Optional event object, typically containing the selected status value (e.g., from a dropdown).
   *
   * The function performs the following steps:
   * 1. Extracts the selected status from the `event` object.
   * 2. Updates the `selectedValue` property to store the selected status for further actions (e.g., clearing the selection).
   * 3. Initializes an array `rowsToUpdate` to track the rows that will be updated.
   * 4. Retrieves the grid's server-side selection state using `this.grid.getServerSideSelectionState()`.
   * 5. Checks if the "Select All" option is enabled and processes all rows accordingly.
   *    - Iterates through all rows using `forEachNode`.
   *    - Updates rows where the `originalStatus` is not set and checks whether the row is applicable for the new status.
   *    - If applicable, the status is updated, and the row is added to `rowsToUpdate`.
   * 6. If not in "Select All" mode, updates only the selected rows using `getSelectedNodes()`.
   * 7. Applies the transaction to the grid using `applyTransaction` to update the rows.
   * 8. Re-selects each node to trigger `onRowSelected` logic and refresh the selection.
   * 9. Refreshes the grid cells visually using `refreshCells` for the rows that were updated.
   *
   * @returns {void} - This function does not return a value but updates the grid based on user input.
   */
  statusSelector(event?: any): void {
    const selectedStatus = event.value; // The status selected from the dropdown

    this.selectedValue = selectedStatus; // For the clear button calculation
    const rowsToUpdate: any[] = [];
    const selectionState = this.grid.getServerSideSelectionState(); // Get the server-side selection state

    // Set flag to indicate bulk selection initiation
    this.bulkSelectionInitiated = true;

    // Check if we are in 'Select All' mode
    if (
      selectionState &&
      Object.keys(selectionState).length > 0 &&
      this.isSelectionStateWithSelectAll(selectionState) &&
      selectionState.selectAll
    ) {
      // Bulk update for all rows
      this.grid.forEachNode((rowNode: any) => {
        if (!rowNode.isSelected()) {
          return;
        } // Skip unselected rows

        const rowData = rowNode.data;

        // Initialize originalStatus if not set
        if (!rowData.originalStatus) {
          rowData.originalStatus = rowData.status;
        }
        // Check if the row should be updated based on the selected status
        if (this.isRowApplicableForStatus(rowData, selectedStatus)) {
          if (selectedStatus === 'Published' && rowData.metricversionstatus !== 'Published') {
            // Fallback to the metricversionstatus if the row can't be transitioned to Published
            // eslint-disable-next-line no-param-reassign
            rowNode.data.status = rowData.metricversionstatus;
          } else {
            // Apply the selected status directly
            // eslint-disable-next-line no-param-reassign
            rowNode.data.status = selectedStatus;
          }
          rowsToUpdate.push(rowNode); // Track the updated rows
        }
      });
    } else {
      // Handle individual rows that are selected
      this.grid.getSelectedNodes().forEach((rowNode: any) => {
        const rowData = rowNode.data;

        // Initialize originalStatus if not set
        if (!rowData.originalStatus) {
          rowData.originalStatus = rowData.status;
        }

        // Check if the row should be updated based on the selected status
        if (this.isRowApplicableForStatus(rowData, selectedStatus)) {
          if (selectedStatus === 'Published' && rowData.metricversionstatus !== 'Published') {
            // Fallback to the metricversionstatus if the row can't be transitioned to Published
            // eslint-disable-next-line no-param-reassign
            rowNode.data.status = rowData.metricversionstatus;
          } else {
            // Apply the selected status directly
            // eslint-disable-next-line no-param-reassign
            rowNode.data.status = selectedStatus;
          }
          rowsToUpdate.push(rowNode); // Track the updated rows
        }
      });
    }

    // Apply the transaction to update the grid with the modified rows
    if (rowsToUpdate.length > 0) {
      this.grid.applyTransaction({ update: rowsToUpdate });
    }

    // Re-select the selected nodes to trigger selection logic
    this.grid.forEachNode(node => {
      if (node.isSelected()) {
        node.setSelected(true); // Just re-selecting the selected ones
      }
    });
    // Re-select individual selected rows
    this.grid.getSelectedNodes().forEach((rowNode: any) => {
      rowNode.setSelected(false);
      rowNode.setSelected(true); // Re-select to trigger selection logic
    });

    // Refresh the grid to reflect the updated status visually
    this.grid.refreshCells({ rowNodes: rowsToUpdate, force: true });

    // Reset bulk selection flag after the operation is complete
    this.bulkSelectionInitiated = false;
  }

  // Check if a row is applicable for the selected status
  isRowApplicableForStatus(rowData: any, selectedStatus: string): boolean {
    const currentStatus = rowData.status; // Get the current status of the row

    // Define the status transition rules
    const statusTransitionRules: { [key: string]: string[] } = {
      Draft: ['Draft'], // Draft can only remain Draft
      Published: ['Draft', 'Published'], // Published can transition to Draft or remain Published
      Revoked: [], // Revoked cannot transition to any other status
    };

    // If the current status is Revoked, don't allow any changes
    if (currentStatus === 'Revoked') {
      return false; // No status can be applied to Revoked
    }

    // Handle bulk selection scenario - apply the selected status if bulk selection is initiated
    if (this.bulkSelectionInitiated) {
      return true; // Allow all valid transitions during bulk selection
    }

    // Handle individual selection scenario (non-bulk)
    return statusTransitionRules[currentStatus]?.includes(selectedStatus) || false;
  }

  /**
   * Handles the event when a row is selected or deselected in the AG Grid.
   * This method updates the selected rows' metrics and manages UI elements like dropdowns
   * based on the current selection state. It also refreshes the grid cells to ensure
   * visual changes reflect the updated selection.
   *
   * @param event - The selection event triggered by AG Grid.
   *                The event object contains the grid's API and other selection details.
   *                Example structure: { api: GridApi, node: RowNode, data: any }
   *
   * The function:
   * 1. Checks if "Select All" is triggered, updating all rows accordingly.
   * 2. Handles individual node selections or deselections via the toggledNodes list.
   * 3. Updates the previously selected nodes set to reflect the current selection.
   * 4. Manages  UI elements ( like dropdowns & calender) based on whether rows are selected.
   * 5. Refreshes grid cells to apply any visual changes due to selection state updates.
   */
  onRowSelected(event: any): void {
    const { api } = event;
    const selectionState = api.getServerSideSelectionState();
    const allRowsSelected = selectionState.toggledNodes.length === api.getDisplayedRowCount();

    // Enable or disable the dropdown based on whether rows are selected
    this.isDropdownEnabled = selectionState.selectAll
      ? selectionState.selectAll
      : selectionState.toggledNodes.length > 0;

    // Handle "Select All" scenario
    if (selectionState && selectionState.selectAll) {
      api.forEachNode((node: any) => {
        if (node.isSelected()) {
          this.updateMetrics(node.data, true); // Update the selected row's metrics
        } else {
          this.updateMetrics(node.data, false); // Update metrics on deselection
        }
      });
    }
    // Handle manually toggled nodes (individual selection or deselection)
    else if (selectionState && selectionState.toggledNodes.length > 0) {
      selectionState.toggledNodes.forEach((nodeId: string) => {
        const node = api.getRowNode(nodeId);

        if (node) {
          if (node.isSelected()) {
            this.updateMetrics(node.data, true); // Update metrics on selection
          } else {
            this.updateMetrics(node.data, false); // Update metrics on deselection
          }
        }
        // Update the header checkbox based on selection state
        if (allRowsSelected) {
          api.selectAll(); // This will check the header checkbox
        } else {
          // Here we can choose to deselect the header if not all rows are selected
          // api.deselectAll();
        }
      });
    }

    // Enable/disable bulk selector based on whether any rows are selected
    // Check selected rows for individual or toggle selection

    // Reset the dropdown if no rows are selected
    if (!selectionState.selectAll && selectionState.toggledNodes.length === 0) {
      this.updatedMetrics = []; // Clear the updated metrics when no rows are selected
      this.resetDropdown();
      this.resetTable();
      this.selectedValue = null;
    }

    // Refresh the grid to reflect any updates visually
    api.refreshCells({ force: true });

    // Trigger statusSelector after bulk selection
    if (this.bulkSelectionInitiated) {
      this.statusSelector({ value: this.selectedValue });
      api.refreshCells({ force: true });
    }
  }

  /**
   * Checks if the row data was modified by a dropdown change.
   * @param {any} rowData - The data of the row.
   * @returns {boolean} - Returns true if the row was changed by a dropdown.
   */
  isDropdownChanged(rowData: any): boolean {
    // Add logic here to track or identify if the row data was modified by a dropdown
    return rowData.modifiedByDropdown === true;
  }

  /**
   * Updates the metric versions based on the selection state (isSelected) of a row.
   * If the row is selected, it either updates the existing metric version or adds a new one.
   * If the row is not selected, it removes the metric version from the list.
   *
   * @param {Object} row - The row containing metric details such as metricId, metricVersionId, startDate, endDate, and status.
   * @param {boolean} isSelected - Determines whether the row is selected or not.
   *
   * Updates:
   * - Adds a metric version if the row is selected and doesn't exist.
   * - Updates an existing metric version if the row is selected and the version exists.
   * - Removes the metric version if the row is not selected, and if no versions remain, the entire metric is removed.
   */
  updateMetrics(row: any, isSelected: boolean): void {
    if (row.status) {
      const updatedMetricVersion = {
        metricVersionId: row.metricversionid,
        startDate: this.appendTimeToDate(row.startdate),
        endDate: this.appendTimeToDate(row.enddate),
        status: row.status.toUpperCase(), // Ensure status is always captured as uppercase
      };

      const metricId = row.metricid;
      const metricIndex = this.updatedMetrics.findIndex(m => m.metricId === metricId);

      /* eslint-disable no-lonely-if */
      if (metricIndex !== -1) {
        // Metric exists
        const metric = this.updatedMetrics[metricIndex];
        const versionIndex = metric.metricVersionDtoList.findIndex(
          v => v.metricVersionId === updatedMetricVersion.metricVersionId
        );

        if (isSelected) {
          // If selected, update or add the metric version
          if (versionIndex !== -1) {
            // Update existing metric version
            metric.metricVersionDtoList[versionIndex] = updatedMetricVersion;
          } else {
            // Add new metric version
            metric.metricVersionDtoList.push(updatedMetricVersion);
          }
        } else {
          // If not selected, remove the metric version
          if (versionIndex !== -1) {
            metric.metricVersionDtoList.splice(versionIndex, 1);

            // If no metric versions are left, remove the metric
            if (metric.metricVersionDtoList.length === 0) {
              this.updatedMetrics = this.updatedMetrics.filter(m => m.metricId !== metricId);
            }
          }
        }
      } else if (isSelected) {
        // Add new metric with its version if the metric is not already in the list
        this.updatedMetrics.push({
          metricId,
          metricVersionDtoList: [updatedMetricVersion],
        });
      }
    }
  }

  appendTimeToDate(date: string): string {
    const dateObj = new Date(date);
    const currentTime = new Date(); // Get the current time

    // Set the time from the current time to the date object
    dateObj.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds());

    // Convert to ISO string or any other desired format
    return dateObj.toISOString(); // Or use a custom format if needed
  }

  // Method to reset the dropdown to its original state
  resetDropdown(): void {
    this.isDropdownEnabled = false; // Disable the dropdown
  }

  // Method to reset the table rows to their original status
  resetTable(): void {
    this.grid.refreshServerSide();
    this.updatedMetrics = []; // Clear out the updated metrics list as the data get retained via calculation
  }

  private initForm(): FormGroup {
    return this.formBuilder.group({
      id: [null, [Validators.maxLength(20)]],
      contractName: ['', [ValidationService.requiredValidator, Validators.maxLength(30)]],
      customerName: ['', [ValidationService.requiredValidator]],
      providerName: ['', [ValidationService.requiredValidator]],
      description: ['', [Validators.maxLength(1000)]],
      version: ['', [Validators.maxLength(20)]],
      startDate: ['', [ValidationService.requiredValidator]],
      endDate: ['', [ValidationService.requiredValidator]],
      status: ['', [Validators.maxLength(20)]],
      statement: ['', [Validators.maxLength(250)]],
      selectionStartDate: ['', [ValidationService.requiredValidator]],
      selectionEndDate: ['', [ValidationService.requiredValidator]],
    });
  }

  private getOrganizationDetails(): void {
    this.organizationService.getMetricTreeData().subscribe((res: any) => {
      this.organizationData = res?.children;
      if (this.viewContrcatData.providerName) {
        this.contractForm
          .get('providerName')
          ?.patchValue(this.getNodeWithKey(this.viewContrcatData.providerName, [res]));
      }
      if (this.viewContrcatData.customerName) {
        this.contractForm
          .get('customerName')
          ?.patchValue(this.getNodeWithKey(this.viewContrcatData.customerName, [res]));
      }
      this.organizationData.forEach((node: TreeNode<any>) => {
        this.expandRecursive(node, true);
        this.disableRecursive(node);
      });
    });
  }

  private getNodeWithKey(key: number | string, nodes: any[]): ITreeConfig | undefined {
    let returnObj;

    // has to define interface for the nodes to remove type any
    Object.keys(nodes).forEach((keys: any) => {
      if (nodes[keys].name === key) {
        returnObj = nodes[keys];
      }

      if (nodes[keys].children) {
        const matchedNode = this.getNodeWithKey(key, nodes[keys].children);

        if (matchedNode) {
          returnObj = matchedNode;
        }
      }
    });

    return returnObj;
  }

  private expandRecursive(node: TreeNode, isExpand: boolean): void {
    const nodeExpand = node;

    nodeExpand.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
    // to check last node
    if (node.children && node.children.length === 0) {
      nodeExpand.expanded = !nodeExpand.expanded;
    }
  }

  private disableRecursive(node: any): void {
    if (node.countOfChildren > 0) {
      const nodeSelected = node;

      nodeSelected.selectable = false;
      node.children.forEach((childNode: any) => {
        this.disableRecursive(childNode);
      });
    }
  }
}
