import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';

import { UdfComponent } from '@app/shared/components/udf/udf.component';
import { MetricPermissionService } from '@app/slm/services/metric-permission.service';
import { MetricsService } from '@app/slm/services/metrics.service';
import { UdfService } from '@app/slm/services/udf.service';

@Component({
  selector: 'app-edit-version',
  templateUrl: './edit-version.component.html',
  styleUrls: ['./edit-version.component.scss'],
})
export class EditVersionComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<boolean>();

  @ViewChild('op', { static: false }) overlayPanel: any;

  @ViewChild('paginationReset') paginationReset!: Table;

  @ViewChild(UdfComponent) udfComponent!: UdfComponent;

  viewData: any;

  minStartDate = new Date('01-01-1901');

  maxEndDate = new Date('12-31-9999');

  minEndDate!: Date;

  createMetricForm!: FormGroup;

  errorMsgList: any = [];

  viewVersionList: any = [];

  selectedVersion: any;

  filteredVersion: any = [];

  statusList: any = [
    { label: 'Draft', class: 'warning-chip' },
    { label: 'Published', class: 'success-chip' },
    { label: 'Revoked', class: 'danger-chip' },
  ];

  selectedStatusFilter: any = { Draft: false, Published: false, Revoked: false };

  metricUDFData: any;

  metricVersionObjectFields: any;

  UDFVersionList: any;

  viewUdfList: any;

  isEditMetricUDF!: boolean;

  constructor(
    private metricService: MetricsService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    public metricPermission: MetricPermissionService,
    private udfService: UdfService
  ) {}

  ngOnInit(): void {
    this.createMetricForm = this.formBuilder.group({
      versionName: ['', [Validators.maxLength(20)]],
      statusValue: [''],
      target: [],
      expected: [],
      startDate: [''],
      endDate: [''],
      status: [''],
      metricVersionStatement: [null, [Validators.maxLength(250)]],
      statusFilter: new FormArray([]),
    });

    this.addCheckboxes();

    this.metricService.isSelectedVersion$.subscribe((res: any) => {
      if (res) {
        this.viewData = res.viewData;
        this.viewUdfList = this.viewData.objectFieldData;
        this.viewVersionList = res.versionList;
        this.patchValue(res.selectedVersion);
        this.selectedVersion = res.selectedVersion;
        this.filteredVersion = this.viewVersionList;
        this.isEditMetricUDF = res.isEditUDFEnabled;
      }
    });

    this.createMetricForm.get('expected')?.disable();
  }

  patchValue(data: any): void {
    this.onSelectStartDate(new Date(data.startDate));

    this.createMetricForm.patchValue({
      versionName: data.versionName,
      target: data.target,
      expected: data.expected,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      status: data.statusValue,
      metricVersionStatement: data.metricVersionStatement,
    });
    if (data.statusValue !== 'Draft') {
      this.createMetricForm.disable();
      if (data.statusValue === 'Published') {
        this.createMetricForm.get('metricVersionStatement')?.enable();
      }
    } else {
      this.createMetricForm.enable();
    }
    // create version UDF here as data will be patched as per version
    if (data.objectFieldData && data.objectFieldData.length > 0) {
      this.UDFVersionList = data.objectFieldData;
      this.udfService.getUDFType(data);
    }
    this.createMetricForm.get('statusFilter')?.enable();
  }

  applyStatusFilter(event: any, field: string): void {
    Object.keys(this.selectedStatusFilter).forEach(key => {
      if (key === field) {
        this.selectedStatusFilter[key] = event.checked;
      }
    });

    // filter the versions from the list
    const filterList: any = [];

    this.paginationReset?.reset();

    this.viewVersionList.forEach((status: any) => {
      if (this.selectedStatusFilter[status.statusValue]) {
        filterList.push(status);
      }
    });

    // check if no filter selected show initial list
    const noFilterSelected = Object.values(this.selectedStatusFilter).every(v => !v);

    if (noFilterSelected) {
      this.filteredVersion = this.viewVersionList;
    } else {
      this.filteredVersion = filterList;
    }
  }

  onClosePanel(event: any): void {
    this.selectedVersion = event.data;
    this.UDFVersionList = [];
    this.patchValue(this.selectedVersion);

    this.overlayPanel.hide();
  }

  onSelectStartDate(event: any): void {
    this.minEndDate = new Date();
    this.minEndDate = new Date(event.getFullYear(), event.getMonth(), event.getDate() + 1);
    this.createMetricForm.get('endDate')?.reset();
  }

  saveUpdatedVersion(metricId: number): void {
    this.errorMsgList = [];
    if (this.createMetricForm.invalid || this.udfComponent.dynamicForm.invalid) {
      this.createMetricForm.markAllAsTouched();
    } else {
      const startDate = this.datePipe.transform(this.createMetricForm.get('startDate')?.value, 'yyyy-MM-dd');
      const endDate = this.datePipe.transform(this.createMetricForm.get('endDate')?.value, 'yyyy-MM-dd');
      const versionId = this.createMetricForm.get('versionName')?.value?.versionId
        ? this.createMetricForm.get('versionName')?.value?.versionId
        : this.selectedVersion.versionId;
      const versionName = this.createMetricForm.get('versionName')?.value;
      const metricVersionStatement = this.createMetricForm.get('metricVersionStatement')?.value;

      const basePayload = {
        versionName: versionName.versionName ? versionName.versionName : versionName,
        target: this.createMetricForm.get('target')?.value,
        expected: this.createMetricForm.get('expected')?.value,
        startDate: startDate ? new Date(startDate).toISOString() : '',
        endDate: endDate ? new Date(endDate).toISOString() : '',
        metricVersionStatement: metricVersionStatement || null,
      };
      const payload = {
        ...basePayload,
        ...(this.metricVersionObjectFields && this.metricVersionObjectFields.length > 0
          ? { objectFields: this.metricVersionObjectFields }
          : {}),
      };

      if (this.metricUDFData && this.metricUDFData.length > 0) {
        this.sendMetricUDFData();
      }

      this.metricService.editVersionDetails(metricId, versionId, payload).subscribe(
        (res: any) => {
          if (res) {
            this.metricService.getCreatedMetricData({ res });
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
              detail: this.translateService.instant('MODULES.CREATE_METRIC.VERSION_EDIT_SUCCESS', {
                metric: this.viewData.metricName,
                version: res.versionName,
              }),
            });
            this.closeViewMetric();
            this.metricService.toogleSidebar(false);
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            const errorList: any = [];

            err.forEach((msg: any) => {
              errorList.push(msg.message);
            });
            this.errorMsgList = errorList;
          }
        }
      );
    }
  }

  closeViewMetric(): void {
    this.viewData = [];
    this.viewVersionList = [];
    this.errorMsgList = [];
    this.minEndDate = new Date();
    this.createMetricForm.reset();
    this.metricService.getEditVersionInfo(false);
  }

  publishVersion(metricId: number): void {
    this.errorMsgList = [];
    const startDate = this.datePipe.transform(this.createMetricForm.get('startDate')?.value, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.createMetricForm.get('endDate')?.value, 'yyyy-MM-dd');
    const versionId = this.createMetricForm.get('versionName')?.value?.versionId
      ? this.createMetricForm.get('versionName')?.value?.versionId
      : this.selectedVersion.versionId;
    const versionName = this.createMetricForm.get('versionName')?.value;
    const metricVersionStatement = this.createMetricForm.get('metricVersionStatement')?.value;

    const basePayload = {
      versionId,
      versionName: versionName.versionName ? versionName.versionName : versionName,
      target: this.createMetricForm.get('target')?.value,
      expected: this.createMetricForm.get('expected')?.value,
      startDate: startDate ? new Date(startDate).toISOString() : '',
      endDate: endDate ? new Date(endDate).toISOString() : '',
      metricVersionStatement: metricVersionStatement || null,
    };

    const payload = {
      ...basePayload,
      ...(this.metricVersionObjectFields && this.metricVersionObjectFields.length > 0
        ? { objectFields: this.metricVersionObjectFields }
        : {}),
    };

    this.metricService.publishVersion(metricId, payload, true).subscribe(
      (res: any) => {
        if (res) {
          if (this.metricUDFData && this.metricUDFData.length > 0) {
            this.sendMetricUDFData();
          }
          this.metricService.getCreatedMetricData({ res });
          this.messageService.add({
            severity: 'success',

            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CREATE_METRIC.VERSION_PUBLISH', {
              metric: this.viewData.metricName,
              version: res.versionName,
            }),
          });
          this.closeViewMetric();
          this.metricService.toogleSidebar(false);
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          const errorList: any = [];

          err.forEach((msg: any) => {
            errorList.push(msg.message);
          });
          this.errorMsgList = errorList;
        }
      }
    );
  }

  cofirmPublish(event: Event): void {
    this.errorMsgList = [];

    if (!this.createMetricForm.get('startDate')?.value || !this.createMetricForm.get('endDate')?.value) {
      const errorList: any = [];
      const msg = {
        message: this.translateService.instant('MODULES.VIEW_METRICS.ERROR'),
        fieldName: 'Date',
      };

      errorList.push(msg.message);

      this.errorMsgList = errorList;
      this.createMetricForm.markAllAsTouched();
      this.udfComponent.dynamicForm.markAllAsTouched();
    } else if (this.createMetricForm.invalid || this.udfComponent.dynamicForm.invalid) {
      this.createMetricForm.markAllAsTouched();
      this.udfComponent.dynamicForm.markAllAsTouched();
    } else {
      this.confirmationService.confirm({
        target: event.target ? event.target : undefined,
        icon: 'pi pi-exclamation-triangle',
        message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_PUBLISH'),
        acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
        rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
        rejectButtonStyleClass: 'p-button-outlined',
        accept: () => {
          this.publishVersion(this.viewData.id);
        },
      });
    }
  }

  revokeVersion(metricId: number): void {
    this.errorMsgList = [];

    const versionId = this.createMetricForm.get('versionName')?.value?.versionId
      ? this.createMetricForm.get('versionName')?.value?.versionId
      : this.selectedVersion.versionId;

    const versionName = this.createMetricForm.get('versionName')?.value;
    const startDate = this.datePipe.transform(this.createMetricForm.get('startDate')?.value, 'yyyy-MM-dd');
    const endDate = this.datePipe.transform(this.createMetricForm.get('endDate')?.value, 'yyyy-MM-dd');
    const metricVersionStatement = this.createMetricForm.get('metricVersionStatement')?.value;

    const basePayload = {
      versionName: versionName.versionName ? versionName.versionName : versionName,
      target: this.createMetricForm.get('target')?.value,
      expected: this.createMetricForm.get('expected')?.value,
      startDate: startDate ? new Date(startDate).toISOString() : '',
      endDate: endDate ? new Date(endDate).toISOString() : '',
      metricVersionStatement: metricVersionStatement || null,
    };
    const payload = {
      ...basePayload,
      ...(this.metricVersionObjectFields && this.metricVersionObjectFields.length > 0
        ? { objectFields: this.metricVersionObjectFields }
        : {}),
    };

    if (this.metricUDFData && this.metricUDFData.length > 0) {
      this.sendMetricUDFData();
    }

    this.metricService.revokeVersion(metricId, versionId, payload).subscribe(
      (res: any) => {
        if (res) {
          this.metricService.getCreatedMetricData({ res });
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('AUTH.DEFAULT_SUCCESS_SUMMARY'),
            detail: this.translateService.instant('MODULES.CREATE_METRIC.VERSION_REVOKE', {
              metric: this.viewData.metricName,
              version: res.versionName,
            }),
          });
          this.closeViewMetric();
          this.metricService.toogleSidebar(false);
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          const errorList: any = [];

          err.forEach((msg: any) => {
            errorList.push(msg.message);
          });
          this.errorMsgList = errorList;
        }
      }
    );
  }

  cofirmRevoke(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ? event.target : undefined,
      icon: 'pi pi-exclamation-triangle',
      message: this.translateService.instant('MODULES.CONTRACTS.CONFIRM_REVOKE'),
      acceptLabel: this.translateService.instant('MODULES.CONTRACTS.YES'),
      rejectLabel: this.translateService.instant('MODULES.CONTRACTS.NO'),
      rejectButtonStyleClass: 'p-button-outlined',
      accept: () => {
        this.revokeVersion(this.viewData.id);
      },
    });
  }

  getTagetValue(): void {
    if (this.createMetricForm.get('target')?.value) {
      this.createMetricForm.get('expected')?.enable();
    } else {
      this.createMetricForm.get('expected')?.disable();
      this.createMetricForm.patchValue({ expected: null });
    }
  }

  /** UDF */
  onFormDataChanged(formData: any): void {
    /**
     * Check if the field has either 'value' or 'valueSetKey'
     * and ensure that null/undefined are properly handled.
     */

    if (formData.metricObjectFields && formData.metricObjectFields.length > 0) {
      this.metricUDFData = formData.metricObjectFields.filter((obj: any) => {
        // Check for value existence: value may be number, string, or present but null/undefined
        const hasValue =
          Object.prototype.hasOwnProperty.call(obj, 'value') && obj.value !== null && obj.value !== undefined;
        const hasValueSetKey =
          Object.prototype.hasOwnProperty.call(obj, 'valueSetKey') &&
          obj.valueSetKey !== null &&
          obj.valueSetKey !== undefined;

        return hasValue || hasValueSetKey;
      });
    }

    if (formData.metricVersionObjectFields && formData.metricVersionObjectFields.length > 0) {
      this.metricVersionObjectFields = formData.metricVersionObjectFields.filter((obj: any) => {
        // Check for value existence: value may be number, string, or present but null/undefined
        const hasValue =
          Object.prototype.hasOwnProperty.call(obj, 'value') && obj.value !== null && obj.value !== undefined;
        const hasValueSetKey =
          Object.prototype.hasOwnProperty.call(obj, 'valueSetKey') &&
          obj.valueSetKey !== null &&
          obj.valueSetKey !== undefined;

        return hasValue || hasValueSetKey;
      });
    }
  }

  sendMetricUDFData(): void {
    this.errorMsgList = [];
    if (this.createMetricForm.invalid || this.udfComponent.dynamicForm.invalid) {
      this.createMetricForm.markAllAsTouched();
    } else {
      const payload = {
        id: this.viewData.id,
        ...(this.metricUDFData && this.metricUDFData.length > 0 ? { objectFieldData: this.metricUDFData } : {}),
      };

      this.metricService.saveUDFMetric(payload).subscribe(
        (res: any) => {
          if (res) {
            this.metricService.getCreatedMetricData({ res });
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            const errorList: any = [];

            err.forEach((msg: any) => {
              errorList.push(msg.message);
            });
            this.errorMsgList = errorList;
          }
        }
      );
    }
  }

  getVersionInfo(obj: any): any {
    return Object.keys(obj).length;
  }

  private addCheckboxes(): void {
    this.statusList.map(() => {
      const control = new FormControl();

      return (this.createMetricForm.get('statusFilter') as FormArray).push(control);
    });
  }
}
