<div class="container-fluid">
  <div class="row">
    <div class="col-6 pb-0">
      <button
        pButton
        type="button"
        label="{{ 'MODULES.METRICS.ADD' | translate }}"
        class="p-button"
        [disabled]="
          !(selected?.levelName === 'Business Offering') ||
          !catalogPermission.hasAddCatalog()
        "
        (click)="openCreateMetricForm()"
      ></button>
    </div>
    <div class="col-6 text-right pb-0">
      <button
        pButton
        type="button"
        class="p-button mb-0"
        label="{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}"
        (click)="clearGrid()"
        [disabled]="!haveFilters"
        *ngIf="selected?.children?.length === 0"
      ></button>
    </div>

    <!-- tree structure -->
    <div class="col-3 col-sm-3">
      <p-tree
        styleClass="tree-height"
        [value]="metricTreeData"
        selectionMode="single"
        [(selection)]="selected"
        (onNodeSelect)="nodeSelect()"
        emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
      >
        <ng-template let-node pTemplate="default">
          <div class="d-flex w-100">
            <i
              [ngClass]="
                node.expanded
                  ? 'pi pi-folder-open mr-2 my-auto tree-folder-icon'
                  : 'pi pi-folder mr-2 my-auto tree-folder-icon'
              "
            ></i>
            <div>
              <h6>{{ node.name }}</h6>
              <p class="mb-0">
                ({{ node.countOfChildren ? node.countOfChildren : '0' }}
                {{
                  node.countOfChildren > 1
                    ? node.childLevelNamePlural
                    : node.childLevelName
                }})
              </p>
            </div>
          </div>
        </ng-template>
      </p-tree>
    </div>

    <!-- folder structure -->
    <ng-container *ngIf="selected?.children?.length > 0">
      <div class="col-9 pt-0">
        <div class="row">
          <ng-container *ngFor="let x of selected.children">
            <div class="col-3">
              <div class="card text-center p-2">
                <i class="pi pi-folder tree-folder-icon"></i>
                <h6 class="mt-2 mb-1">{{ x.name }}</h6>
                <p class="my-1">
                  {{ x.levelName }} ({{
                    x.countOfChildren ? x.countOfChildren : '0'
                  }}
                  {{
                    x.countOfChildren > 1
                      ? x.childLevelNamePlural
                      : x.childLevelName
                  }})
                </p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <!-- table structure -->
    <!-- headers?.length is supposed to display skeleton in near future -->
    <ng-container *ngIf="selected?.children?.length === 0">
      <div class="col-9 col-sm-9">
        <ag-grid-angular
          class="ag-theme-balham custom-ag-table"
          [columnDefs]="headers"
          [gridOptions]="gridOptions"
          (gridReady)="onGridReady($event)"
          (sortChanged)="onSortChange()"
          (filterModified)="onFilterModified($event)"
          #agGrid
        >
        </ag-grid-angular>
      </div>
    </ng-container>
  </div>

  <!-- view catalog details -->
  <p-sidebar
    [(visible)]="isCatalogViewEnabled"
    position="right"
    styleClass="p-sidebar-md"
    (onHide)="close()"
    *ngIf="isCatalogViewEnabled"
  >
    <app-view-catalog
      (closeClicked)="isCatalogViewEnabled = $event"
    ></app-view-catalog>
  </p-sidebar>

  <!-- create catalog metric -->
  <p-sidebar
    [(visible)]="isCreateMetricEnabled"
    position="right"
    styleClass="p-sidebar-md"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isCreateMetricEnabled"
  >
    <app-add-catalog-metric
      (closeClicked)="isCreateMetricEnabled = $event"
    ></app-add-catalog-metric>
  </p-sidebar>
</div>
