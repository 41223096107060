import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { ContractDetails } from '../interface/metrics.tree';
import { environment } from '@env/environment';

const routes = {
  contractsTable: `${environment.SLMUrl}/contracts`,
  contractDetails: `${environment.SLMUrl}/contracts/details`,
  metricsByContractID: `${environment.SLMUrl}/hierarchies/getAllMetricsForBusinessServicesByContract/`,
  catalogTree: `${environment.SLMUrl}/hierarchies/hierarchyOfBusinessServiceForMetricCatalog`,
  catalogTable: `${environment.SLMUrl}/metricCatalog/businessOfferings/`,
  reportContractHeader: `${environment.SLMUrl}/reports/contracts/header`,
  reportContractData: `${environment.SLMUrl}/reports/contracts/data`,
  saveDuplicateContract: `${environment.SLMUrl}/contracts/copy`,
  duplicateContract: `${environment.SLMUrl}/contracts/copy/validate`,
  providerEntity: `${environment.SLMUrl}/metricconnectors/param/hierarchy?name=Provider`,
  metricVersionContractHeaders: `${environment.SLMUrl}/reports`,
  metricVersionData: `${environment.SLMUrl}/reports/Metric Versions Per Contract`,
};

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  viewData = new BehaviorSubject<any>('');

  selectedMetricList = new Subject<any>();

  createdContract = new Subject<any>();

  private isEditContractSidebar = new Subject<any>();

  isEditOpen$ = this.isEditContractSidebar.asObservable();

  private isAddContractSidebar = new Subject<any>();

  isAddOpen$ = this.isAddContractSidebar.asObservable();

  private isViewContractSidebar = new Subject<any>();

  isViewOpen$ = this.isViewContractSidebar.asObservable();

  private isSidebarWidthInc = new Subject<any>();

  isSidebarWidth$ = this.isSidebarWidthInc.asObservable();

  private sendBulkUpdate = new Subject<any>();

  isBulkUpdate$ = this.sendBulkUpdate.asObservable();

  // Observable to notify when a row is updated
  private rowUpdatedSource = new Subject<{ rowId: string; field: string; newValue: string }>();

  // Expose the observable that other components can subscribe to
  rowUpdated$ = this.rowUpdatedSource.asObservable();

  countOfMetric = new BehaviorSubject<any>('');

  // Method to call when a row is updated
  notifyRowUpdated(updateData: { rowId: string; field: string; newValue: string }): void {
    this.rowUpdatedSource.next(updateData); // Emit the update data to any subscribers
  }

  constructor(private httpClient: HttpClient) {}

  toogleSidebar(data: any): void {
    this.isEditContractSidebar.next(data);
  }

  toogleAddSidebar(data: any): void {
    this.isAddContractSidebar.next(data);
  }

  toogleViewSidebar(data: any): void {
    this.isViewContractSidebar.next(data);
  }

  getCreatedContract(data: any): void {
    this.createdContract.next(data);
  }

  getViewData(data: ContractDetails): void {
    this.viewData.next(data);
  }

  getSelectedMetricList(data: any): void {
    this.selectedMetricList.next(data);
  }

  getSidebarWidth(data: boolean): void {
    this.isSidebarWidthInc.next(data);
  }

  bulkSelectUpdate(data: boolean): void {
    this.sendBulkUpdate.next(data);
  }

  getContractData(params: any = {}): any {
    return this.httpClient.get(`${routes.contractsTable}`, { params });
  }

  getContractByID(id: any): any {
    return this.httpClient.get(`${routes.contractDetails}/${id}`);
  }

  getMetricsByContractID(id: any, page?: number, size?: number): any {
    return this.httpClient.get(`${routes.metricsByContractID}${id}?page=${page}&size=${size}`);
  }

  addContract(payload: any): any {
    return this.httpClient.post(routes.contractsTable, payload);
  }

  updateMatricCatlogIds(contractId: number, payload: any): any {
    return this.httpClient.put(`${routes.contractsTable}/${contractId}/metrics`, payload);
  }

  publishContract(payload: any): any {
    return this.httpClient.post(`${routes.contractsTable}/publish`, payload);
  }

  addVersion(id: any, payload: any): any {
    return this.httpClient.post(`${routes.contractsTable}/${id}/versions`, payload);
  }

  publishVersion(id: number, payload: any): any {
    return this.httpClient.post(`${routes.contractsTable}/${id}/versions/publish`, payload);
  }

  editVersion(id: number, versionId: number, payload: any): any {
    return this.httpClient.put(`${routes.contractsTable}/${id}/versions/${versionId}`, payload);
  }

  revokeVersion(id: number, versionId: number, payload: any): any {
    return this.httpClient.put(`${routes.contractsTable}/${id}/versions/${versionId}/revoke`, payload);
  }

  getCatalogTreeData(params: any = {}): any {
    return this.httpClient.get(routes.catalogTree, { params });
  }

  getCatalogTableData(businessId: string, params: any = {}): any {
    return this.httpClient.get(`${routes.catalogTable + businessId}`, { params });
  }

  getContractHeader(params: any = {}): any {
    return this.httpClient.get(`${routes.reportContractHeader}`, { params });
  }

  reportContractData(body?: any, params: any = {}): any {
    return this.httpClient.post(`${routes.reportContractData}`, body, { params });
  }

  validateDupContract(id: number, payload: any): any {
    return this.httpClient.post(`${routes.duplicateContract}/${id}`, payload);
  }

  saveDuplicateContract(id: number, payload: any): any {
    return this.httpClient.post(`${routes.saveDuplicateContract}/${id}`, payload);
  }

  getProviderEntityList(): any {
    return this.httpClient.get(`${routes.providerEntity}`);
  }

  getMetricVersionHeader(params: any = {}): any {
    return this.httpClient.get(`${routes.metricVersionContractHeaders}/${params.reportIdentifier}/header`);
  }

  getMetricVersionData(body?: any, params: any = {}): any {
    return this.httpClient.post(`${routes.metricVersionData}/data`, body, { params });
  }

  // to capture contract details when we redirect from contract page to metric page with page filtered with contract name
  getCountOfMetric(data: ContractDetails): void {
    this.countOfMetric.next(data);
  }
}
