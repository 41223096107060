import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { FavMenuService } from '@app/shared/services/fav-menu.service';

@Component({
  selector: 'app-fav-menu',
  templateUrl: './fav-menu.component.html',
  styleUrls: ['./fav-menu.component.scss'],
})
export class FavMenuComponent implements AfterViewInit {
  @Input() menuItems: any = [];

  @ViewChild('menuWrapper', { read: ElementRef }) ulMenuWrapperRef!: ElementRef;

  constructor(private router: Router, private favMenuService: FavMenuService) {}

  getIcon(ref: string): any {
    switch (ref) {
      case 'folder':
        return 'assets/icons/fav/folder.svg';

      case 'report':
        return 'assets/icons/fav/report.svg';

      case 'static':
        return 'assets/icons/fav/static.svg';

      case 'action':
        return 'assets/icons/fav/calculation.svg';

      default:
        break;
    }
  }

  // Menu Scrollbar
  ngAfterViewInit(): void {
    const mainMenuWrapper = this.ulMenuWrapperRef.nativeElement;
    const liMenuItemsList = mainMenuWrapper.querySelectorAll('li.menu-item.has-children');
    const subMenuItemsList = mainMenuWrapper.querySelectorAll('.submenu li.menu-item.has-children');

    // hover over a menu item that has a submenu (1st level)
    liMenuItemsList?.forEach((menuItems: any) => {
      menuItems.addEventListener('mouseover', () => {
        // grab the menu item's position relative to its positioned parent
        const subMenuList = menuItems.querySelectorAll('.submenu')[0];

        // place the submenu in the correct position relevant to the menu item
        subMenuList.style.top = `${menuItems.offsetTop - mainMenuWrapper.scrollTop}px`;
      });
    });

    // nested submenu (nth level)
    subMenuItemsList?.forEach((menuItems: any) => {
      menuItems.addEventListener('mouseover', () => {
        const subMenuList = menuItems.querySelectorAll('.submenu')[0];
        const subMenuParent = subMenuList.parentElement.parentElement;

        if (subMenuParent) {
          subMenuList.style.top = '0px';
        }
      });
    });
  }

  // fav menu navigation
  routeNavigate(item: any = {}): void {
    if (item.url && !item.isDeleted && item.isAccessible) {
      if (item.favTypeName !== 'action') {
        this.router.navigateByUrl(item.url);
      }

      this.favMenuService.toggleFavMenu(false);

      // mark new item as accessed item
      if (item.isNew) {
        this.favMenuService.markItemAccessed(item.id).subscribe();
      }
    }
  }
}
