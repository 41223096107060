import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';

import { AboutComponent } from './components/about/about.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ActionsComponent } from './components/fav-menu/actions/actions.component';
import { FavMenuComponent } from './components/fav-menu/fav-menu.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { LanguageToggleComponent } from './components/language-toggle/language-toggle.component';
import { UdfComponent } from './components/udf/udf.component';
import { SessionExpiredComponent } from '@app/authentication/components/session-expired/session-expired.component';
import { WebformComponent } from '@app/report/components/webform/webform.component';
import { SLMRoutingModule } from '@app/slm/slm-routing.module';

@NgModule({
  declarations: [
    ConfirmationComponent,
    AboutComponent,
    LanguageToggleComponent,
    SessionExpiredComponent,
    UdfComponent,
    FavoritesComponent,
    FavMenuComponent,
    WebformComponent,
    ActionsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TooltipModule,
    ToggleButtonModule,
    ButtonModule,
    CardModule,
    DividerModule,
    TableModule,
    MenuModule,
    SkeletonModule,
    SLMRoutingModule,
    TreeModule,
    DropdownModule,
    InputTextModule,
    KeyFilterModule,
    CalendarModule,
    SidebarModule,
    ChipModule,
    ToastModule,
    InfiniteScrollModule,
    BreadcrumbModule,
    SidebarModule,
    AccordionModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    CalendarModule,
    AutoCompleteModule,
    KeyFilterModule,
    InputNumberModule,
    TreeSelectModule,
    FileUploadModule,
    ConfirmPopupModule,
    PaginatorModule,
    DialogModule,
    OverlayPanelModule,
    CheckboxModule,
  ],
  exports: [
    ConfirmationComponent,
    LanguageToggleComponent,
    AboutComponent,
    SessionExpiredComponent,
    UdfComponent,
    FavoritesComponent,
    FavMenuComponent,
    WebformComponent,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
