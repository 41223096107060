<div class="container-fluid">
  <div
    class="d-flex justify-content-between pt-2"
    style="width: calc(100% - 460px)"
  >
    <button
      pButton
      type="button"
      icon="pi pi-plus"
      label="{{ 'MODULES.CONTRACTS.ADD_CONTRACT' | translate }}"
      [disabled]="!contractPermission.hasAddContract()"
      class="p-button"
      (click)="isCreateContract = !isCreateContract"
    ></button>
    <button
      pButton
      type="button"
      class="p-button position-button"
      label="{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}"
      (click)="clearGrid()"
      [disabled]="!haveFilters"
    ></button>
  </div>
  <div class="row">
    <ng-container>
      <ag-grid-angular
        style="margin-top: 10px !important"
        class="ag-theme-balham tble contract-tabel"
        [columnDefs]="headers"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClick($event)"
        (sortChanged)="onSortChange()"
        (filterModified)="onFilterModified($event)"
        #agGrid
      >
      </ag-grid-angular>
    </ng-container>
  </div>

  <!-- view contract details -->
  <p-sidebar
    [(visible)]="isContractviewEnabled"
    position="right"
    (onHide)="close()"
    styleClass="p-sidebar-md"
    *ngIf="isContractviewEnabled"
  >
    <app-view-contract
      (closeClicked)="onCloseViewContrcat($event)"
    ></app-view-contract>
  </p-sidebar>

  <!-- Create new contract -->
  <p-sidebar
    [(visible)]="isCreateContract"
    position="right"
    (onHide)="close()"
    styleClass="p-sidebar-md"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isCreateContract"
  >
    <app-add-contract
      (closeClicked)="isCreateContract = $event"
    ></app-add-contract>
  </p-sidebar>

  <!-- create contract version -->
  <p-sidebar
    [(visible)]="isCreateversion"
    position="right"
    (onHide)="close()"
    styleClass="p-sidebar-md"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isCreateversion"
  >
    <app-add-contract-version
      (closeClicked)="isCreateversion = $event"
    ></app-add-contract-version>
  </p-sidebar>

  <!-- edit contract version -->
  <p-sidebar
    [(visible)]="isEditVersionEnabled"
    position="right"
    (onHide)="close()"
    styleClass="p-sidebar-md"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isEditVersionEnabled"
  >
    <app-edit-contract-version
      (closeClicked)="isEditVersionEnabled = $event"
    ></app-edit-contract-version>
  </p-sidebar>

  <p-sidebar
    [(visible)]="isDuplicateContrcat"
    position="right"
    styleClass="p-sidebar-lg right-sidebar-view"
    [dismissible]="false"
    [closeOnEscape]="false"
    *ngIf="isDuplicateContrcat"
    [style]="{ width: use60Percent ? '60%' : '75%' }"
  >
    <app-duplicate-contract
      [viewContrcatData]="viewContrcatData"
      (closeClicked)="isDuplicateContrcat = $event"
    ></app-duplicate-contract>
  </p-sidebar>
</div>
