<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>MODULES.CONTRACTS.DETAILS</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="px-4">
  <div class="row mt-2">
    <!--Contract Id -->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.CONTRACTS.CONTRACT_ID
    </div>
    <div class="col-9 col-sm-9">
      {{ viewData?.contractNo }}
      <p-skeleton *ngIf="!viewData?.contractNo"></p-skeleton>
    </div>

    <!--Contract name-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.CONTRACT_NAME
    </div>
    <div class="col-9 col-sm-9">
      {{ viewData?.contractName }}
      <p-skeleton *ngIf="!viewData?.contractName"></p-skeleton>
    </div>

    <!--Contract status-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.CONTRACT_STATUS
    </div>
    <div class="col-9 col-sm-9">
      <span
        *ngIf="viewData?.status"
        [ngClass]="
          viewData?.status === 'Published'
            ? 'success-chip'
            : viewData?.status === 'Draft'
            ? 'warning-chip'
            : 'danger-chip'
        "
      >
        {{ viewData?.status }}
      </span>
      <p-skeleton *ngIf="!viewData?.status"></p-skeleton>
    </div>

    <!--Description-->
    <div class="col-3 col-sm-3 fw-bold" translate>
      MODULES.VIEW_METRICS.DESCRIPTION
    </div>
    <div class="col-9 col-sm-9">
      {{ viewData?.description }}
      <p-skeleton *ngIf="!viewData?.description"></p-skeleton>
    </div>

    <!--Customer-->
    <div class="col-3 col-sm-3 fw-bold" translate>MODULES.METRICS.CUSTOMER</div>
    <div class="col-9 col-sm-9">
      {{ viewData?.customerName }}
      <p-skeleton *ngIf="!viewData?.customerName"></p-skeleton>
    </div>

    <!--Provider-->
    <div class="col-3 col-sm-3 fw-bold" translate>MODULES.METRICS.PROVIDER</div>
    <div class="col-9 col-sm-9">
      {{ viewData?.providerName }}
      <p-skeleton *ngIf="!viewData?.providerName"></p-skeleton>
    </div>
  </div>
</div>

<!-- Adding version details -->
<h5 class="mt-4 px-4" translate>MODULES.CONTRACTS.VERSION_DETAILS</h5>
<p-divider></p-divider>

<form [formGroup]="createVersionForm">
  <div class="px-4">
    <div class="row">
      <!-- version name -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <input
            class="w-100 p-inputtext-sm"
            type="text"
            pInputText
            formControlName="versionName"
          />
          <label for="versionName">{{
            'MODULES.CONTRACTS.VERSION_NAME' | translate
          }}</label>
        </span>
        <div *ngIf="createVersionForm.get('versionName')?.touched">
          <small
            *ngIf="createVersionForm.get('versionName')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 20}' }}
          </small>
        </div>
      </div>

      <!-- start date -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-calendar
            styleClass="w-100"
            formControlName="startDate"
            [showIcon]="true"
            dateFormat="dd-mm-yy"
            [minDate]="minStartDate"
            (onSelect)="onSelectStartDate($event)"
            [readonlyInput]="true"
            appendTo="body"
          >
          </p-calendar>
          <label for="startDate">{{
            'MODULES.CONTRACTS.START_DATE' | translate
          }}</label>
        </span>
      </div>

      <!-- end date -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-calendar
            styleClass="w-100"
            formControlName="endDate"
            [showIcon]="true"
            [minDate]="minEndDate"
            [maxDate]="maxEndDate"
            dateFormat="dd-mm-yy"
            [readonlyInput]="true"
            [defaultDate]="minEndDate"
            appendTo="body"
          >
          </p-calendar>
          <label for="endDate">{{
            'MODULES.CONTRACTS.END_DATE' | translate
          }}</label>
        </span>
      </div>

      <!-- statement -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <textarea
            class="w-100"
            pInputTextarea
            formControlName="statement"
          ></textarea>
          <label for="statement">{{
            'MODULES.CONTRACTS.STATEMENT' | translate
          }}</label>
        </span>

        <div *ngIf="createVersionForm.get('statement')?.touched">
          <small
            *ngIf="createVersionForm.get('statement')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
          </small>
        </div>
      </div>

      <!-- api error msg block -->
      <ng-container *ngIf="errorMsgList?.length">
        <ul class="ps-3">
          <li class="text-danger" *ngFor="let msg of errorMsgList">
            {{ msg }}
          </li>
        </ul>
      </ng-container>
    </div>

    <!-- btn section -->
    <div class="row mt-2">
      <div class="col-4 col-sm-4">
        <button
          pButton
          type="button"
          label="{{ 'MODULES.CONTRACTS.CANCEL' | translate }}"
          class="p-button-outlined"
          style="width: 110px"
          (click)="resetForm()"
        ></button>
      </div>
      <div class="col-4 col-sm-4 text-center">
        <button
          pButton
          type="submit"
          label="{{ 'MODULES.CONTRACTS.SAVE' | translate }}"
          [disabled]="!contractPermission.hasEditContractVersion()"
          class="p-button"
          style="width: 110px"
          (click)="saveNewVersion(viewData.id)"
        ></button>
      </div>
      <div class="col-4 col-sm-4 text-right">
        <button
          pButton
          type="submit"
          label="{{ 'MODULES.CONTRACTS.PUBLISH' | translate }}"
          [disabled]="!contractPermission.hasPublishContractVersion()"
          class="p-button"
          style="width: 110px"
          (click)="cofirmPublish($event)"
        ></button>
      </div>
    </div>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
