<div
  class="container-fluid d-flex justify-content-center align-items-center extreme"
>
  <div class="flash-card">
    <p-card>
      <h3 class="fw-bold mb-3">{{ data.code }}</h3>
      <h4>{{ data.title }}</h4>
      <p>{{ data.description }}</p>
      <p-button
        styleClass="p-button-sm px-4"
        iconPos="left"
        icon="pi pi-arrow-left"
        label="Back"
        (click)="getBack()"
      ></p-button>
    </p-card>
  </div>
</div>
