import { Component, Input, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { concatMap, Subject, takeUntil } from 'rxjs';

import { menuItemObj } from '../favMenu';
import { CalculationsService } from '@app/data/calculations/services/calculations.service';
import { FavMenuService } from '@app/shared/services/fav-menu.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnDestroy {
  @Input() actionMenuItem!: menuItemObj;

  actionData: any;

  errorList: any = [];

  private destroy$ = new Subject<void>();

  constructor(
    private favMenuService: FavMenuService,
    private calculationsService: CalculationsService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  getIconKey(state: string): string {
    switch (state) {
      case 'Completed':
      case 'Failed':
        return 'start.svg';
      case 'In Progress':
        return 'loading.svg';
      default:
        return 'start.svg';
    }
  }

  getDetails(): void {
    const processorId = this.actionMenuItem?.processorId;

    this.calculationsService.getActionDetails(processorId).subscribe(
      (res: any) => {
        if (res) {
          this.actionData = res;
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('MODULES.DATA.CALCULATIONS.ACTION_SUCCESS'),
          });

          // mark new item as accessed item
          this.favMenuService
            .markItemAccessed(this.actionMenuItem?.id)
            .pipe(
              // update fav menu for status update
              concatMap(() => this.favMenuService.updateFavMenu(this.actionData)),
              takeUntil(this.destroy$)
            )
            .subscribe();
        }
      },
      (err: any) => {
        if (err && Array.isArray(err)) {
          const errorList: any = [];

          err.forEach((msg: any) => {
            errorList.push(msg.message);
          });
          this.errorList = errorList;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete(); // Ensure complete cleanup
  }
}
