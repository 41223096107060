<div class="container-fluid">
  <div class="row">
    <div class="col-12 text-right">
      <button
        class="p-button"
        pButton
        label="{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}"
        (click)="clearGrid()"
        [disabled]="!haveFilters"
      ></button>
    </div>
  </div>
  <div class="row">
    <ng-container>
      <ag-grid-angular
        class="ag-theme-balham custom-ag-table"
        [columnDefs]="headers"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClick($event)"
        (sortChanged)="onSortChange()"
        (filterModified)="onFilterModified($event)"
        #agGrid
      >
      </ag-grid-angular>
    </ng-container>
  </div>

  <!-- view metric details -->
  <p-sidebar
    [(visible)]="isUploadHistory"
    position="right"
    styleClass="p-sidebar-lg right-sidebar-view"
    [closeOnEscape]="false"
    *ngIf="isUploadHistory"
  >
    <app-upload-history
      (closeClicked)="isUploadHistory = $event"
    ></app-upload-history>
  </p-sidebar>
</div>
