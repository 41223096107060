import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';

const routes = {
  menu: `${environment.SLMUrl}/favorites`,
  markAccessed: `${environment.SLMUrl}/favorites/markAccessed`,
};

@Injectable({
  providedIn: 'root',
})
export class FavMenuService {
  private loadFavMenuSubject: Subject<void> = new Subject();

  loadFavMenu$: Observable<void> = this.loadFavMenuSubject.asObservable();

  updatedMenuItems = new Subject<any>();

  isFavMenuOpen = new BehaviorSubject<boolean>(false);

  isFavMenuOpen$ = this.isFavMenuOpen.asObservable();

  constructor(private httpClient: HttpClient) {}

  loadFavMenu(): void {
    this.loadFavMenuSubject.next();
  }

  updateFavMenu(data: any): Observable<any> {
    this.updatedMenuItems.next(data);

    return of(null);
  }

  toggleFavMenu(data: any): void {
    this.isFavMenuOpen.next(data);
  }

  getFavMenu(): any {
    return this.httpClient.get(routes.menu);
  }

  markItemAccessed(id: number, params: any = {}): any {
    return this.httpClient.post(`${routes.markAccessed}/${id}`, params, { responseType: 'text' });
  }
}
