<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" translate>MODULES.CONTRACTS.ADD_TITLE</h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<form [formGroup]="contractForm">
  <div class="px-4">
    <div class="row">
      <!-- contract id -->
      <div class="col-12">
        <span class="w-100 p-float-label my-2">
          <input
            class="w-100 p-inputtext-sm"
            type="text"
            pInputText
            formControlName="id"
          />
          <label for="id">{{
            'MODULES.CONTRACTS.CONTRACT_ID' | translate
          }}</label>
        </span>
        <div *ngIf="contractForm.get('id')?.touched">
          <small
            *ngIf="contractForm.get('id')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 20}' }}
          </small>
        </div>
      </div>

      <!-- contract name -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <input
            class="w-100 p-inputtext-sm"
            type="text"
            pInputText
            formControlName="name"
          />
          <label for="name">{{ 'MODULES.CONTRACTS.NAME' | translate }}</label>
        </span>
        <div *ngIf="contractForm.get('name')?.touched">
          <small
            *ngIf="contractForm.get('name')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
          <small
            *ngIf="contractForm.get('name')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 30}' }}
          </small>
        </div>
      </div>

      <!-- description -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <textarea
            class="w-100"
            pInputTextarea
            formControlName="description"
          ></textarea>
          <label for="name">{{
            'MODULES.CONTRACTS.DESCRIPTION' | translate
          }}</label>
        </span>
        <div *ngIf="contractForm.get('description')?.touched">
          <small
            *ngIf="contractForm.get('description')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 1000}' }}
          </small>
        </div>
      </div>

      <!-- customer -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1" *ngIf="organizationData?.length">
          <p-treeSelect
            formControlName="customer"
            containerStyleClass="w-100 tree-select-label"
            [options]="organizationData"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{ 'MODULES.CONTRACTS.CUSTOMER' | translate }}"
            (onNodeSelect)="nodeSelect()"
            emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
          >
            <ng-template let-node pTemplate="default">
              <div class="d-flex w-100">
                <i
                  [ngClass]="
                    node.expanded
                      ? 'pi pi-folder-open mr-2 my-auto tree-folder-icon'
                      : 'pi pi-folder mr-2 my-auto tree-folder-icon'
                  "
                ></i>
                <div class="my-auto">
                  <h6>{{ node.name }}</h6>
                </div>
              </div>
            </ng-template>

            <!-- to display selected value -->
            <ng-template let-node pTemplate="value">
              {{ node?.name ? node?.name : '' }}
            </ng-template>
          </p-treeSelect>
          <label for="provider">{{
            'MODULES.CONTRACTS.CUSTOMER' | translate
          }}</label>
        </span>
        <div *ngIf="contractForm.get('customer')?.touched">
          <small
            *ngIf="contractForm.get('customer')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
        <p-skeleton *ngIf="!organizationData?.length"></p-skeleton>
      </div>

      <!-- provider -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1" *ngIf="organizationData?.length">
          <p-treeSelect
            formControlName="provider"
            containerStyleClass="w-100 tree-select-label"
            [options]="organizationData"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            placeholder="{{ 'MODULES.CONTRACTS.PROVIDER' | translate }}"
            (onNodeSelect)="nodeSelect()"
            emptyMessage="{{ 'AUTH.DEFAULT_EMPTY_MSG' | translate }}"
          >
            <ng-template let-node pTemplate="default">
              <div class="d-flex w-100">
                <i
                  [ngClass]="
                    node.expanded
                      ? 'pi pi-folder-open mr-2 my-auto tree-folder-icon'
                      : 'pi pi-folder mr-2 my-auto tree-folder-icon'
                  "
                ></i>
                <div class="my-auto">
                  <h6>{{ node.name }}</h6>
                </div>
              </div>
            </ng-template>
            <!-- to display selected value -->
            <ng-template let-node pTemplate="value">
              {{ node?.name ? node?.name : '' }}
            </ng-template>
          </p-treeSelect>
          <label for="provider">{{
            'MODULES.CONTRACTS.PROVIDER' | translate
          }}</label>
        </span>
        <div *ngIf="contractForm.get('provider')?.touched">
          <small
            *ngIf="contractForm.get('provider')?.errors?.['required']"
            class="text-danger"
          >
            {{ 'VALIDATION.REQUIRED' | translate }}
          </small>
        </div>
        <small
          *ngIf="contractForm.get('provider')?.errors?.['invalid']"
          class="text-danger"
        >
          {{
            'VALIDATION.INVALID_INPUT'
              | translate : { field_1: 'Customer', field_2: 'Provider' }
          }}
        </small>
        <p-skeleton *ngIf="!organizationData?.length"></p-skeleton>
      </div>
    </div>
  </div>

  <!-- version details -->
  <div>
    <div class="flex">
      <button
        pButton
        class="px-4 py-0 p-button-link"
        label="{{ 'MODULES.CONTRACTS.ADD_METRICS' | translate }}"
        (click)="isPopupVisible = true"
      ></button>

      <!-- metric list remove this as this is only to validate if getting the correct list or not -->
      <div class="px-2" *ngIf="selectedMetrics?.metricCatalogIds?.length">
        Total selected metrics: {{ selectedMetrics?.metricCatalogIds?.length }}
      </div>
    </div>
    <h5 translate class="mt-4 px-4">MODULES.CONTRACTS.VERSION_DETAILS</h5>
    <p-divider></p-divider>
  </div>

  <div class="px-4">
    <div class="row">
      <!-- version name -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <input
            class="w-100 p-inputtext-sm"
            type="text"
            pInputText
            formControlName="versionName"
          />
          <label for="versionName">{{
            'MODULES.CONTRACTS.VERSION_NAME' | translate
          }}</label>
        </span>
        <div *ngIf="contractForm.get('versionName')?.touched">
          <small
            *ngIf="contractForm.get('versionName')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 20}' }}
          </small>
        </div>
      </div>

      <!-- start date -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-calendar
            styleClass="w-100"
            formControlName="startDate"
            [showIcon]="true"
            dateFormat="dd-mm-yy"
            [minDate]="minStartDate"
            (onSelect)="onSelectStartDate($event)"
            [readonlyInput]="true"
            appendTo="body"
          >
          </p-calendar>
          <label for="startDate">{{
            'MODULES.CONTRACTS.START_DATE' | translate
          }}</label>
        </span>
      </div>

      <!-- end date -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <p-calendar
            styleClass="w-100"
            formControlName="endDate"
            [showIcon]="true"
            [minDate]="minEndDate"
            [maxDate]="maxEndDate"
            dateFormat="dd-mm-yy"
            [readonlyInput]="true"
            [defaultDate]="minEndDate"
            appendTo="body"
          >
          </p-calendar>
          <label for="endDate">{{
            'MODULES.CONTRACTS.END_DATE' | translate
          }}</label>
        </span>
      </div>

      <!-- statement -->
      <div class="col-12">
        <span class="w-100 p-float-label my-1">
          <textarea
            class="w-100"
            pInputTextarea
            formControlName="statement"
          ></textarea>
          <label for="statement">{{
            'MODULES.CONTRACTS.STATEMENT' | translate
          }}</label>
        </span>
        <div *ngIf="contractForm.get('statement')?.touched">
          <small
            *ngIf="contractForm.get('statement')?.errors?.['maxlength']"
            class="text-danger"
          >
            {{ 'VALIDATION.MAXLENGTH' | translate : '{requiredLength: 250}' }}
          </small>
        </div>
      </div>
    </div>

    <!-- api error msg block -->
    <ng-container *ngIf="errorMsgList?.length">
      <div class="error-block">
        <li *ngFor="let msg of errorMsgList">{{ msg }}</li>
      </div>
    </ng-container>

    <!-- btn section -->
    <div class="row mt-2">
      <div class="col-4 col-sm-4">
        <button
          pButton
          type="button"
          label="{{ 'MODULES.CONTRACTS.CANCEL' | translate }}"
          class="p-button-outlined"
          style="width: 110px"
          (click)="closeCreateContract()"
        ></button>
      </div>
      <div class="col-4 col-sm-4 text-center">
        <button
          pButton
          type="submit"
          label="{{ 'MODULES.CONTRACTS.SAVE' | translate }}"
          [disabled]="!contractPermission.hasEditContractVersion()"
          class="p-button"
          style="width: 110px"
          (click)="saveContract()"
        ></button>
      </div>
      <div class="col-4 col-sm-4 text-right">
        <button
          pButton
          type="submit"
          label="{{ 'MODULES.CONTRACTS.PUBLISH' | translate }}"
          [disabled]="!contractPermission.hasPublishContractVersion()"
          class="p-button"
          style="width: 110px"
          (click)="cofirmPublish($event)"
        ></button>
      </div>
    </div>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>

<p-dialog
  header="{{ 'MODULES.CONTRACTS.ADD_METRICS' | translate }}"
  [(visible)]="isPopupVisible"
  *ngIf="isPopupVisible"
  [modal]="true"
  [draggable]="false"
  [maximizable]="true"
  [style]="{ width: '98vw' }"
  styleClass="custom-p-dialog"
>
  <app-choose-catalog
    (closeClicked)="isPopupVisible = $event"
  ></app-choose-catalog>
</p-dialog>
