import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import * as go from 'gojs';

import * as jsonData from './model.json';

@Component({
  selector: 'app-agreement-config-component',
  templateUrl: './agreement-config-component.component.html',
  styleUrls: ['./agreement-config-component.component.scss'],
})
export class AgreementConfigComponentComponent implements AfterViewInit {
  @ViewChild('myDiagramDiv') diagramDiv!: ElementRef;

  myDiagram!: go.Diagram;

  constructor() {}

  ngAfterViewInit(): void {
    // null checking
    if (this.diagramDiv) {
      this.createView();
    } else {
      alert('diagramDiv is null');
    }
  }

  createView(): void {
    // basic settings for horizontal layout
    this.myDiagram = new go.Diagram('myDiagramDiv', {
      initialContentAlignment: go.Spot.Left,
      initialAutoScale: go.AutoScale.UniformToFill,
      layout: new go.LayeredDigraphLayout({ direction: 0 }),
      'undoManager.isEnabled': true,
    });

    // Add a listener to detect changes in the model
    this.myDiagram.addDiagramListener('Modified', e => {
      const saveButton = document.getElementById('saveButton') as HTMLButtonElement;

      if (saveButton) {
        saveButton.disabled = !this.myDiagram.isModified;
      } // Enable or disable save button based on changes
    });

    // Add a listener to detect changes in the model
    // this.myDiagram.addDiagramListener("LinkDrawn", function (e) {
    //   // Show success message
    //   alert("A link (joint) has been successfully created between nodes!");
    // });

    function makePort(name: any, leftside: any): any {
      const port = new go.Shape('Rectangle', {
        fill: 'black',
        stroke: null,
        desiredSize: new go.Size(8, 8),
        portId: name, // declare this object to be a "port"
        toMaxLinks: 1, // don't allow more than one link into a port
        cursor: 'pointer', // show a different cursor to indicate potential link point
      });

      const lab = new go.TextBlock(
        name, // the name of the port
        { font: '9px opensans', stroke: 'white' }
      );

      const panel = new go.Panel('Horizontal', { margin: new go.Margin(2, 0) });

      // set up the port/panel based on which side of the node it will be on
      if (leftside) {
        port.toSpot = go.Spot.Left;
        port.toLinkable = true;
        lab.margin = new go.Margin(1, 0, 0, 1);
        panel.alignment = go.Spot.TopLeft;
        panel.add(port);
        panel.add(lab);
      } else {
        port.fromSpot = go.Spot.Right;
        port.fromLinkable = true;
        lab.margin = new go.Margin(1, 1, 0, 0);
        panel.alignment = go.Spot.TopRight;
        panel.add(lab);
        panel.add(port);
      }

      return panel;
    }

    function makeTemplate(
      myDiagram: go.Diagram,
      typename: any,
      icon: any,
      background: any,
      inports: any,
      outports: any
    ): void {
      const node = new go.Node('Spot', {
        // Add a click event handler - single click on node
        // click: function (e, obj) {
        //   alert('Node clicked: ' + typename);
        // },
      }).add(
        new go.Panel('Auto', { width: 100, height: 120 }).add(
          // styling of each node
          new go.Shape('RoundedRectangle', {
            fill: background,
            stroke: null,
            strokeWidth: 0,
            spot1: go.Spot.TopLeft,
            spot2: go.Spot.BottomRight,
          }),
          new go.Panel('Table').add(
            //  first block - typename as title
            new go.TextBlock(typename, {
              row: 0,
              margin: 3,
              maxSize: new go.Size(80, NaN),
              stroke: 'white',
              font: 'bold 12px opensans',
            }),

            //  second block - icon
            new go.Picture(icon, { row: 1, width: 13, height: 13, margin: 5, scale: 2.0 }),

            // third block - nodeDataArray.name
            new go.TextBlock({
              row: 2,
              margin: 3,
              editable: true,
              maxSize: new go.Size(80, 40),
              stroke: 'white',
              font: '12px opensans',
            }).bindTwoWay('text', 'name')
          )
        ),
        new go.Panel('Vertical', {
          alignment: go.Spot.Left,
          alignmentFocus: new go.Spot(0, 0.5, 8, 0),
        }).add(...inports),
        new go.Panel('Vertical', {
          alignment: go.Spot.Right,
          alignmentFocus: new go.Spot(1, 0.5, -8, 0),
        }).add(...outports)
      );

      myDiagram.nodeTemplateMap.set(typename, node);
    }

    // templates should have typename exactly as given by API (nodeDataArray.type) to map the desired config

    makeTemplate(
      this.myDiagram,
      'Table',
      'assets/icons/go-js-icons/roll-up.svg',
      'forestgreen',
      [],
      [makePort('OUT', false)]
    );

    // altered css to display values
    makeTemplate(
      this.myDiagram,
      'Data Source',
      'assets/icons/go-js-icons/roll-up.svg',
      '#0074A4',
      [],
      [makePort('OUT', false)]
    );

    makeTemplate(
      this.myDiagram,
      'Join',
      'assets/icons/go-js-icons/roll-up.svg',
      '#002F43',
      [makePort('L', true), makePort('R', true)],
      [makePort('UL', false), makePort('ML', false), makePort('M', false), makePort('MR', false), makePort('UR', false)]
    );

    makeTemplate(
      this.myDiagram,
      'Project',
      'assets/icons/go-js-icons/roll-up.svg',
      'darkcyan',
      [makePort('', true)],
      [makePort('OUT', false)]
    );

    makeTemplate(
      this.myDiagram,
      'Filter',
      'assets/icons/go-js-icons/roll-up.svg',
      '#C4A800',
      [makePort('', true)],
      [makePort('OUT', false), makePort('INV', false)]
    );

    makeTemplate(
      this.myDiagram,
      'Group',
      'assets/icons/go-js-icons/roll-up.svg',
      'mediumpurple',
      [makePort('', true)],
      [makePort('OUT', false)]
    );

    makeTemplate(
      this.myDiagram,
      'Sort',
      'assets/icons/go-js-icons/roll-up.svg',
      'sienna',
      [makePort('', true)],
      [makePort('OUT', false)]
    );

    makeTemplate(
      this.myDiagram,
      'Rollup',
      'assets/icons/go-js-icons/roll-up.svg',
      '#8B8A7C',
      [makePort('IN', true)],
      [makePort('OUT', false)]
    );

    // altered css to display values
    makeTemplate(
      this.myDiagram,
      'Evaluate',
      'assets/icons/go-js-icons/roll-up.svg',
      '#DB2F16',
      [makePort('IN', true)],
      [makePort('OUT', false)]
    );

    this.myDiagram.linkTemplate = new go.Link({
      routing: go.Routing.Orthogonal,
      corner: 25,
      relinkableFrom: true,
      relinkableTo: true,
    }).add(
      // css for connectors
      new go.Shape({ stroke: 'black', strokeWidth: 2 }),
      new go.Shape({ stroke: 'black', fill: 'black', toArrow: 'Standard' })
    );

    // model json imported
    this.myDiagram.model = go.Model.fromJson(jsonData);
  }

  // Function to save the current state of the diagram
  saveDiagram(): void {
    const json = this.myDiagram.model.toJson();

    // This can be used to send the JSON to a server or save it locally
    // console.log(json);
    alert('Diagram saved successfully!');
    this.myDiagram.isModified = false; // Reset the modified state after saving
  }
}
