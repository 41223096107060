/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RowSpanHelperService {
  constructor() {}

  createData(fields: any, mappedData: any): any {
    const dataFields = fields.map((fl: any) => fl.field);
    let makingData: any = [];

    mappedData.forEach((data: any) => {
      makingData.push(this.preferredOrder(data, dataFields));
    });

    const groupedObj = {};
    const array: any = [];

    fields.forEach((fl: any) => {
      if (fl.enableSpan) {
        array.push(fl.field);
        groupedObj[fl.field] = this.getCountsByGroupedRows(array, makingData);
      }
    });

    if (groupedObj && Object.keys(groupedObj).length > 0) {
      makingData = this.flattenObject(groupedObj[Object.keys(groupedObj)[Object.keys(groupedObj).length - 1]]).flat();
    }

    const newDataSets: any = this.calculateDynamicRowSpan(makingData, fields);

    // // Find duplicate record where rowspan needed
    // const duplicatesRecords = makingData.map((current: any, i: any, array: any) => {
    //   const obj = {};

    //   fields.forEach((field: any) => {
    //     if (field.enableSpan) {
    //       const columnName = `isDuplicate${field.field}`;

    //       obj[columnName] =
    //         array.slice(0, i).filter((el: any) => String(el[field.field]).startsWith(current[field.field])).length > 0;
    //     }
    //   });

    //   return {
    //     ...current,
    //     ...obj,
    //   };
    // });

    // duplicatesRecords.forEach((data: any) => {
    //   const obj = {};

    //   fields.forEach((field: any) => {
    //     const columnName = `isDuplicate${field.field}`;

    //     if (field.enableSpan) {
    //       const d = this.findObjectByLabel(groupedObj[field.field][data[dataFields[0]]], data[field.field]);

    //       obj[field.field] = {
    //         title: data[field.field],
    //         rowSpan: data[columnName] ? 0 : d?.length || 0,
    //       };
    //       obj[columnName] = data[columnName];
    //     } else {
    //       obj[field.field] = {
    //         title: data[field.field],
    //         rowSpan: 0,
    //       };
    //     }
    //   });

    //   newDataSets.push(obj);
    // });

    return newDataSets;
  }

  // private calculateDynamicRowSpan(data: any): any {
  //   // if (!data || data.length === 0) {
  //   //   return [];
  //   // }

  //   // // Dynamically extract the keys from the first object in the input data
  //   // const keys = Object.keys(data[0]);

  //   // // Initialize the output array with default rowSpan = 1 for all dynamic keys
  //   // const output = data.map((item: any) => {
  //   //   const obj = {};

  //   //   keys.forEach(key => {
  //   //     obj[key] = { title: item[key], rowSpan: 1 };
  //   //   });

  //   //   return obj;
  //   // });

  //   // // Calculate rowSpan for each dynamic key starting from the last element
  //   // for (let i = data.length - 2; i >= 0; i--) {
  //   //   keys.forEach((key: any, index: number) => {
  //   //     if (data[i][key] === data[i + 1][key]) {
  //   //       // if (
  //   //       //   (index !== 0 && data[i][keys[index - 1]] !== data[i + 1][keys[index - 1]]) ||
  //   //       //   data[i][keys[index - 2]] !== data[i + 1][keys[index - 2]]
  //   //       // ) {
  //   //       //   output[i][key].rowSpan = output[i + 1][key].rowSpan - 1;
  //   //       // } else {
  //   //         output[i][key].rowSpan = output[i + 1][key].rowSpan + 1;
  //   //         output[i + 1][key].rowSpan = 0; // Set the rowSpan of the next duplicate to 0
  //   //       // }
  //   //     }
  //   //   });
  //   // }

  //   // return output;

  //   if (!data || data.length === 0) {
  //     return [];
  //   }

  //   // Dynamically extract the keys from the first object in the input data
  //   const keys = Object.keys(data[0]);

  //   // Initialize the output array with default rowSpan = 1 for all dynamic keys
  //   const output = data.map((item: any) => {
  //     const obj = {};

  //     keys.forEach(key => {
  //       obj[key] = { title: item[key], rowSpan: 1 };
  //     });

  //     return obj;
  //   });

  //   // Function to check if all previous key values are the same between two rows
  //   // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  //   const arePreviousKeysSame = (row1: any, row2: any, index: number) => {
  //     for (let i = 0; i < index; i++) {
  //       if (row1[keys[i]].value !== row2[keys[i]].value) {
  //         return false;
  //       }
  //     }

  //     return true;
  //   };

  //   // Calculate rowSpan for each dynamic key starting from the second last element
  //   for (let i = data.length - 2; i >= 0; i--) {
  //     keys.forEach((key, keyIndex) => {
  //       if (data[i][key] === data[i + 1][key] && arePreviousKeysSame(output[i], output[i + 1], keyIndex)) {
  //         output[i][key].rowSpan = output[i + 1][key].rowSpan + 1;
  //         output[i + 1][key].rowSpan = 0; // Set the rowSpan of the next duplicate to 0
  //       }
  //     });
  //   }

  //   return output;
  // }

  private calculateDynamicRowSpan(data: any, fields: any): any {
    if (!data || data.length === 0) {
      return [];
    }

    // Dynamically extract the keys from the first object in the input data
    const keys = Object.keys(data[0]);

    // Initialize the output array with default rowSpan = 1 for all dynamic keys
    const output = data.map((item: any) => {
      const obj = {};

      keys.forEach(key => {
        obj[key] = { value: item[key], rowSpan: 1 };
      });

      return obj;
    });

    // Function to recursively check if all previous key values are the same between two rows
    const arePreviousKeysSameRecursively = (currentRow: any, nextRow: any, currentKeyIndex: number): any => {
      if (currentKeyIndex === 0) {
        return currentRow[keys[0]].value === nextRow[keys[0]].value;
      }

      return (
        currentRow[keys[currentKeyIndex]].value === nextRow[keys[currentKeyIndex]].value &&
        arePreviousKeysSameRecursively(currentRow, nextRow, currentKeyIndex - 1)
      );
    };

    // Calculate rowSpan for each dynamic key starting from the second last element
    // eslint-disable-next-line for-direction, no-plusplus
    for (let i = data.length - 2; i >= 0; i--) {
      keys.forEach((key, keyIndex) => {
        if (
          fields.find((row: any) => {
            return row.enableSpan && key === row.field;
          })
        ) {
          if (
            data[i][key] === data[i + 1][key] && // Current and next row have the same key value
            arePreviousKeysSameRecursively(output[i], output[i + 1], keyIndex) // All previous keys match
          ) {
            output[i][key].rowSpan = output[i + 1][key].rowSpan + 1;
            output[i + 1][key].rowSpan = 0; // Set the rowSpan of the next duplicate to 0
          }
        } else {
          output[i][key].rowSpan = 0;
        }
      });
    }

    return output;
  }

  private preferredOrder(obj: any, order: any): any {
    const newObject: any = {};

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < order.length; i++) {
      if (typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, order[i])) {
        newObject[order[i]] = obj[order[i]];
      }
    }

    return { ...newObject, data: obj.data };
  }

  private findObjectByLabel(obj: any, label: any): any {
    if (Array.isArray(obj)) {
      return obj;
    }

    return this.findByKey(obj, label);
  }

  private findByKey(obj: any, kee: any): any {
    if (kee in obj) {
      return obj[kee];
    }
    for (const n of Object.values(obj)
      .filter(Boolean)
      .filter(v => typeof v === 'object')) {
      const found = this.findByKey(n, kee);

      if (found) {
        return found;
      }
    }
  }

  private flattenObject(input: any): any {
    const result = [];

    for (const key in input) {
      if (!Object.prototype.hasOwnProperty.call(input, key)) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
        const subFlatObject = this.flattenObject(input[key]);

        for (const subkey in subFlatObject) {
          result.push(subFlatObject[subkey]);
        }
      } else {
        result.push(input[key]);
      }
    }

    return result;
  }

  private getCountsByGroupedRows(groups: any, makingData: any): any {
    const grouped = {};

    makingData.forEach((a: any) => {
      groups
        .reduce((o: any, g: any, i: any) => {
          // take existing object,
          // eslint-disable-next-line no-param-reassign
          o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {}); // or generate new obj, or

          return o[a[g]]; // at last, then an array
        }, grouped)
        .push(a);
    });

    return grouped;
  }
}
