<div class="right-sidebar-header">
  <h5 class="px-4 fw-bold d-inline" *ngIf="uploadDataSet">
    {{ uploadDataSet.sourcereport }}
  </h5>
  <p-divider styleClass="mb-0"></p-divider>
</div>

<div class="container-fluid mt-2">
  <div class="row">
    <div class="col-12 text-right pb-0">
      <button
        class="p-button mb-2"
        pButton
        label="{{ 'MODULES.METRICS.CLEAR_FILTER' | translate }}"
        (click)="clearGrid()"
        [disabled]="!haveFilters"
      ></button>
    </div>
    <div class="col-12 col-sm-12">
      <ag-grid-angular
        style="width: 100%; height: calc(100vh - 114px)"
        [columnDefs]="headers"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        (cellClicked)="onCellClick($event)"
        (sortChanged)="onSortChange()"
        (filterModified)="onFilterModified($event)"
        #agGrid
      >
      </ag-grid-angular>
    </div>
  </div>
</div>
