import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { MessageService, TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';

import { ReportService } from '@app/report/services/report.service';

@Component({
  selector: 'app-webform',
  templateUrl: './webform.component.html',
  styleUrls: ['./webform.component.scss'],
})
export class WebformComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter<boolean>();

  dynamicForm!: FormGroup;

  rowDataSubscription!: Subscription;

  rowData: any;

  reportId!: number;

  formHeader!: string;

  webFormRowId!: number;

  cellAttribute: any;

  getWebFormData: any = [];

  valueSetList: any = [];

  nullData: any = { key: '', title: ' ' };

  hierarchyList: any = [];

  errorList: any = [];

  selectedHierarchyValue: any;

  constructor(
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private reportService: ReportService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.rowDataSubscription = this.reportService.viewUploadData.subscribe((res: any) => {
      if (res) {
        this.rowData = res.data;
        this.cellAttribute = res.property;

        // TODO: have to check if this check is required or not
        if (this.cellAttribute.name === 'Drill To Webform Add') {
          this.reportId = this.cellAttribute.data;
          this.formHeader = `Add: ${res.header}`;
          this.getAttributesField();
        }

        // TODO: have to check if both webforms can be handleed in single conditions
        if (this.cellAttribute.name === 'Drill To Webform Edit') {
          this.reportId = this.cellAttribute.data;
          this.formHeader = `Edit: ${res.header}`;
          this.webFormRowId = res.data.idValue;
          this.getAttributesField();
        }
      }
    });
  }

  getAttributesField(): void {
    const params = this.webFormRowId ? { webFormRowId: this.webFormRowId } : {};

    this.reportService.getFormAttributes(this.reportId, params).subscribe((res: any) => {
      if (res) {
        this.getWebFormData = res.customUploadAttributeDtoList;
        this.getWebFormData.forEach((type: any) => {
          // Hierarchy API call
          if (type.fieldType === 'Hierarchy') {
            this.getHierarchyList(type);
          }

          // Value-Set (dropdown list) API call
          if (type.fieldType === 'Value Set') {
            this.getValueSetByid(type.valueSetId, type);
          }
        });

        // form control creation
        this.getFormControlsFields();
      }
    });
  }

  getHierarchyList(hierarchyDetail: any): void {
    const id = hierarchyDetail.hierarchyParentNodeId;
    let childId = hierarchyDetail.hierarchyChildNodeId;

    this.reportService.getHierarchyFieldData(id).subscribe((res: any) => {
      if (res) {
        this.hierarchyList = res.children;
        this.hierarchyList.forEach((node: TreeNode<any>) => {
          if (hierarchyDetail.correlatedFieldName) {
            // get value from the table
            const corelatedFieldValue = hierarchyDetail.correlatedFieldName.toLowerCase();

            switch (hierarchyDetail.webformValueType) {
              // edit webform the value will be picked from API
              case 'Current Value':
                childId = this.webFormRowId
                  ? Number(hierarchyDetail.value)
                  : this.rowData[`${corelatedFieldValue}Value`];
                break;
              case 'Specific Metric Value':
                childId = this.rowData[`${corelatedFieldValue}Value`];
                break;
              default:
                return childId;
            }
          }

          this.expandRecursive(node, true, childId);
        });

        // patch hierarchy value by mapping the business Id with hierarchy list
        if (hierarchyDetail.fieldType === 'Hierarchy') {
          this.dynamicForm.patchValue({ [hierarchyDetail.fieldName]: this.selectedHierarchyValue });
        }
      }
    });
  }

  getValueSetByid(valueSetIds: number, valueSetData: any): void {
    this.reportService.getValueSet(valueSetIds).subscribe((res: any) => {
      if (res) {
        this.valueSetList[valueSetIds] = res;
        const fieldData = valueSetData;

        if (!fieldData.isMandatory) {
          this.valueSetList[valueSetIds].unshift(this.nullData);
        }

        // get value from the table
        if (fieldData.correlatedFieldName) {
          const corelatedFieldValue = fieldData.correlatedFieldName.toLowerCase();

          switch (fieldData.webformValueType) {
            // edit webform the value will be picked from API
            case 'Current Value':
              fieldData.value = this.webFormRowId ? fieldData.value : this.rowData[`${corelatedFieldValue}Value`];
              break;
            case 'Specific Metric Value':
              fieldData.value = this.rowData[`${corelatedFieldValue}Value`];
              break;
            default:
              return fieldData.value;
          }
        }

        // Patch value of field type value-set
        if (fieldData.value) {
          this.valueSetList[valueSetIds]?.forEach((element: any) => {
            if (element.title === fieldData.value || element.key === fieldData.value) {
              this.dynamicForm.patchValue({ [fieldData.fieldName]: element.key });
            }
          });
        }
      }
    });
  }

  getFormControlsFields(): void {
    const formGroupFields: any = {};

    this.getWebFormData.forEach((field: any) => {
      let value: any;
      const validators: any = this.setControlsValidators(field);

      // patch value from the table cells if correlatedFieldName & webformsValueType attributes available
      if (field.correlatedFieldName) {
        let corelatedFieldValue = field.correlatedFieldName.toLowerCase();

        switch (field.webformValueType) {
          // edit webform the value will be picked from API
          case 'Current Value':
            corelatedFieldValue = this.webFormRowId ? field.value : this.rowData[`${corelatedFieldValue}Value`];
            break;
          case 'Specific Metric Value':
            corelatedFieldValue = this.rowData[`${corelatedFieldValue}Value`];
            break;
          case 'Group By Field':
            corelatedFieldValue = this.cellAttribute.field;
            break;
          default:
            return corelatedFieldValue;
        }

        // to patch value from table data using value instead of formattedValue
        switch (field.fieldType) {
          case 'Date and Time':
          case 'Date':
          case 'Month':
          case 'Year':
            // eslint-disable-next-line no-nested-ternary
            value = corelatedFieldValue
              ? Number.isNaN(Date.parse(corelatedFieldValue))
                ? ''
                : new Date(corelatedFieldValue)
              : '';
            break;
          case 'Hierarchy':
          case 'Value Set':
            // patch value after getting list of Hierarchy and Value Set
            value = null;
            break;
          default:
            // For other types, set the value directly
            value = corelatedFieldValue;
            break;
        }
      } else {
        value = field.value ? field.value : null;
      }

      // Patch value in case of select along with date and date-time format type
      if (
        field.value &&
        (field.fieldType === 'Date and Time' ||
          field.fieldType === 'Date' ||
          field.fieldType === 'Month' ||
          field.fieldType === 'Year')
      ) {
        value = new Date(value);
      }

      formGroupFields[field.fieldName] = [{ value, disabled: field.isLocked }, validators];
    });

    this.dynamicForm = this.formBuilder.group(formGroupFields);
  }

  setControlsValidators(field: any): any {
    const validators: any = [];

    if (field.isMandatory) {
      validators.push(Validators.required);
    }
    if (field.fieldLength) {
      validators.push(Validators.maxLength(field.fieldLength));
    }

    if (field.fieldType === 'Number') {
      validators.push(Validators.pattern('^(?=.*\\d)\\d{0,12}(?:\\.\\d{1,4})?$'));
    }

    return validators;
  }

  submitRowForm(): void {
    this.errorList = [];
    if (this.dynamicForm.invalid) {
      this.dynamicForm.markAllAsTouched();
    } else {
      let field: any;
      const webFormDataAttributeDtoList: any[] = [];

      Object.keys(this.dynamicForm.getRawValue()).forEach(key => {
        const controlValue = this.dynamicForm.getRawValue()[key];

        // Find the corresponding field data based on fieldName
        const fieldData = this.getWebFormData.find((data: any) => data.fieldName === key);

        // If field data is not found, skip this key
        if (!fieldData) {
          return;
        }

        // Create payload object field as it will be mapped as per webformfield data
        if (fieldData.fieldId) {
          field = {
            fieldId: fieldData.fieldId,
            fieldName: fieldData.fieldName,
          };
        }

        // Set the value based on fieldType using switch statement
        switch (fieldData.fieldType) {
          case 'Date and Time':
          case 'Date':
          case 'Month':
          case 'Year':
            // If it's a Date or Date and Time, transform the value using DatePipe
            field.value = this.datePipe.transform(controlValue, fieldData.format);
            break;
          case 'Hierarchy':
            field.value = controlValue?.businessId;
            break;
          case 'Value Set':
            field.value = controlValue || null;
            break;
          default:
            // For other types, set the value directly
            field.value = controlValue;
            break;
        }

        webFormDataAttributeDtoList.push(field);
      });

      let payload = {
        webFormDataAttributeDtoList,
      };

      // to edit webform have to send webform row Id
      if (this.webFormRowId) {
        const webFormRowId: any = { webFormRowId: this.webFormRowId };

        payload = { ...webFormRowId, ...payload };
      }

      // web forms save API
      this.reportService.addWebForm(this.reportId, payload).subscribe(
        (res: any) => {
          if (res) {
            this.messageService.add({
              severity: 'success',
              summary:
                this.cellAttribute.name === 'Drill To Webform Add'
                  ? this.translateService.instant('MODULES.REPORTS.ADD_WEBFORM')
                  : this.translateService.instant('MODULES.REPORTS.EDIT_WEBFORM'),
            });
            this.reportService.getUpdatedReport(res);
            this.resetForm();
          }
        },
        (err: any) => {
          if (err && Array.isArray(err)) {
            const errorList: any = [];

            err.forEach((msg: any) => {
              errorList.push(msg.message);
            });
            this.errorList = errorList;
          }
        }
      );
    }
  }

  resetForm(): void {
    this.dynamicForm.reset();
    this.errorList = [];
    this.closeClicked.emit(false);
  }

  ngOnDestroy(): void {
    this.rowDataSubscription.unsubscribe();
  }

  private expandRecursive(node: TreeNode, isExpand: boolean, childId: any): void {
    const nodeExpand = node;

    nodeExpand.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode: any) => {
        // get the Hierarchy value from the list using business Id/name
        if (childNode.businessId === childId || childNode.name === childId) {
          this.selectedHierarchyValue = childNode;
        }

        this.expandRecursive(childNode, isExpand, childId);
      });
    }
    // to check last node
    if (node.children && node.children.length === 0) {
      nodeExpand.expanded = !nodeExpand.expanded;
    }
  }
}
