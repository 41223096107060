import { Component, EventEmitter, Output } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { merge } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Output() clearSubmenu = new EventEmitter<void>();

  breadcrumbs: MenuItem[] = [];

  urlObtainedInitial: any;

  home: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private translate: TranslateService) {
    const onNavigationEnd = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged()
    );

    merge(this.translate.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          this.breadcrumbs = this.generateBreadcrumbItems(this.activatedRoute.root);
        })
      )
      .subscribe(() => {
        this.translateItemsLabels();
      });
  }

  generateBreadcrumbItems(route: ActivatedRoute, url = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    // If no routeConfig is avalailable we are on the root path
    const label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data['breadcrumb'] : '';
    const icon = route.routeConfig && route.routeConfig.data ? route.routeConfig.data['icon'] : '';
    const path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
    const currentUrl = this.router.url.replace('/', '');

    // handling of module slm/distributions/reports
    const isRouteActive = route.routeConfig && route.routeConfig.data ? route.routeConfig.data['routeActive'] : false;

    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    let breadcrumb: MenuItem = {
      label,
      routerLink: isRouteActive ? nextUrl : '',
      // requiredPermission: children.snapshot?.data?.requiredPermission
      //   ? children.snapshot?.data?.requiredPermission
      //   : '',
    };

    // If the route is dynamic route such as ':id', remove it
    const lastRoutePart: any = path?.split('/').pop();
    const isDynamicRoute = lastRoutePart?.startsWith(':');
    let dynamicRouteURL: any = {};

    if (isDynamicRoute && !!route.snapshot) {
      const paramName = lastRoutePart?.split(':')[1];
      const firstRoutePart: any = path?.split('/').shift();
      const newPath = path?.replace(lastRoutePart, route.snapshot.params[paramName]);

      dynamicRouteURL = {
        label: route.snapshot.params[paramName],
        routerLink: newPath ? `${url}/${newPath}` : url,
      };

      const updatedLink = {
        routerLink: firstRoutePart ? `${url}/${firstRoutePart}` : url,
        styleClass: isRouteActive ? 'breadcrum-active' : '',
      };

      breadcrumb = { ...breadcrumb, ...updatedLink };
    }

    if (currentUrl === 'welcome') {
      this.home = '';
      breadcrumb = {};
      this.clearSubmenu.emit(); // Emit event to clear the submenu in the layout
    }

    if (icon === 'home' && currentUrl !== 'welcome') {
      this.home = { icon: 'pi pi-home', routerLink: ['/welcome'], styleClass: 'breadcrum-active' };
      breadcrumb = {};
    }

    // Only adding route with non-empty label
    let newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

    newBreadcrumbs = dynamicRouteURL.label ? [...newBreadcrumbs, dynamicRouteURL] : [...newBreadcrumbs];

    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.generateBreadcrumbItems(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }

  translateItemsLabels(): void {
    this.breadcrumbs = this.breadcrumbs.map((item: any) => {
      return {
        ...item,
        label: this.translate.instant(item.label),
      };
    });
  }
}
