<button
  class="p-link"
  pTooltip="{{ 'HEADER.FAVORITES' | translate }}"
  tooltipPosition="bottom"
  (click)="toggleFavMenu()"
>
  <i class="pi pi-star-fill icon-size-color"></i>
</button>

<div *ngIf="isVisible" class="favMenu" (click)="$event.stopPropagation()">
  <!-- tabs -->
  <div class="border-bottom px-3 py-4">
    <span
      [class.active]="isFirstActive"
      class="mr-2 p-8x"
      role="button"
      (click)="activateFirst()"
      translate
      >FAVORITES.USER_SPECIFIC</span
    >
    <span
      [class.active]="!isFirstActive"
      class="p-8x"
      role="button"
      (click)="activateSecond()"
      translate
      >FAVORITES.ROLE_SPECIFIC</span
    >
  </div>

  <!-- Content for User-specific tab -->
  <div *ngIf="isFirstActive">
    <app-fav-menu [menuItems]="userMenu"></app-fav-menu>
    <p
      *ngIf="!userMenu.length"
      class="mb-0 px-3 d-flex align-items-center no-data"
      translate
    >
      ABOUT.NO_DATA
    </p>
  </div>

  <!-- Content for Role-specific tab -->
  <div *ngIf="!isFirstActive">
    <app-fav-menu [menuItems]="roleMenu"></app-fav-menu>
    <p
      *ngIf="!roleMenu.length"
      class="mb-0 px-3 d-flex align-items-center no-data"
      translate
    >
      ABOUT.NO_DATA
    </p>
  </div>
</div>
