import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

const routes = {
  uploadHierarchy: `${environment.SLMUrl}/hierarchies/getTreeByHierarchyName/Folders`,
  uploadData: `${environment.SLMUrl}/reports/uploads`,
  downloadata: `${environment.SLMUrl}/reports/`,
  uploadDetailsHeaders: `${environment.SLMUrl}/reports/Uploader%20Details/header`,
  uploadDetailsData: `${environment.SLMUrl}/reports/Uploader%20Details/data`,
  uploadHistoryHeaders: `${environment.SLMUrl}/reports/Uploader%20History/header`,
  uploadHistoryData: `${environment.SLMUrl}/reports/Uploader%20History/data`,
};

@Injectable({
  providedIn: 'root',
})
export class UploadServiceService {
  viewUploadData = new BehaviorSubject<any>('');

  getBusinessId = new BehaviorSubject<any>('');

  getBackToUpload = new BehaviorSubject<any>('');

  constructor(private httpClient: HttpClient) {}

  getUploadHierarchy(params: any = {}): any {
    return this.httpClient.get(`${routes.uploadHierarchy}`, { params });
  }

  getUploadData(id: number, params: any): any {
    return this.httpClient.get(`${routes.uploadData}/${id}`, { params });
  }

  getUploadViewData(data: any): void {
    this.viewUploadData.next(data);
  }

  getBusinessInfo(data: any): any {
    this.getBusinessId.next(data);
  }

  // getUploadHistoryData(id: number, params: any = {}): any {
  //   return this.httpClient.get(`${routes.uploadHistoryData}/${id}`, { params });
  // }

  getUploadHistoryInfo(data: any): void {
    this.getBackToUpload.next(data);
  }

  downloadAllUploadData(uploadId: any, params: any = {}): Observable<HttpResponse<any>> {
    return this.httpClient.get(`${routes.downloadata}${uploadId}/download`, {
      params,
      responseType: 'blob',
      observe: 'response', // to observe the full HTTP response
    });
  }

  getUploadDetailsHeaders(params: any = {}): any {
    return this.httpClient.get(`${routes.uploadDetailsHeaders}`, { params });
  }

  getUploadDetailsData(body: any, params: any = {}): any {
    return this.httpClient.post(`${routes.uploadDetailsData}`, body, { params });
  }

  getUploadHistoryHeaders(params: any = {}): any {
    return this.httpClient.get(`${routes.uploadHistoryHeaders}`, { params });
  }

  getUploadHistoryData(body: any, params: any = {}): any {
    return this.httpClient.post(`${routes.uploadHistoryData}`, body, { params });
  }
}
